import React from "react";
import { Link } from "react-router-dom";

const Dashboard = () => {
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="jumbotron p-5 bg-secondary border rounded-3">
          <div className="container">
            <h1 className="display-6 fw-bold text-light">Welcome</h1>
            <p className="col-md-8 fs-6 text-light">
              {process.env.REACT_APP_NAME} Dashboard
            </p>
            <Link to={"/pos"} className="btn btn-primary btn-lg">
              POS Page
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
