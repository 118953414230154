import React from "react";
import { PlusCircle } from "react-feather";
import { useDispatch } from "react-redux";
import { toggleModalAdd } from "src/redux/store/transferStockOut";
const mainTitle = "Stock Opname";
const subtitle = "Manage your stock adjustment";

const Header = () => {
  const dispatch = useDispatch();
  return (
    <div className="page-header">
      <div className="add-item d-flex">
        <div className="page-title">
          <h4>{mainTitle}</h4>
          <h6>{subtitle}</h6>
        </div>
      </div>
      {/* <ul className="table-top-head">
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip}>
              <Link>
                <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
              </Link>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
              <Link data-bs-toggle="tooltip" data-bs-placement="top">
                <ImageWithBasePath src="assets/img/icons/excel.svg" alt="img" />
              </Link>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
              <Link data-bs-toggle="tooltip" data-bs-placement="top">
                <i data-feather="printer" className="feather-printer" />
              </Link>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
              <Link data-bs-toggle="tooltip" data-bs-placement="top">
                <RotateCcw />
              </Link>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
              <Link
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                id="collapse-header"
                className={data ? "active" : ""}
                onClick={() => {
                  dispatch(setToogleHeader(!data));
                }}
              >
                <ChevronUp />
              </Link>
            </OverlayTrigger>
          </li>
        </ul> */}
      <div className="page-btn">
        <a
          className="btn btn-added"
          role="button"
          onClick={() => dispatch(toggleModalAdd())}
        >
          <PlusCircle className="me-2" />
          Create
        </a>
      </div>
    </div>
  );
};

export default Header;
