export const PAYMENT_METHOD = [
  "DEBIT CARD - MANDIRI",
  "CREDIT CARD - MANDIRI VIMAS",
  "CASH RUPIAH",
  "VOUCHER",
  "CREDIT CARD - BCA AMEX",
  "CREDIT CARD - BCA VIMAS",
  "OTHER INCOME",
  "BANK TRANSFER",
];
