import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "src/core/img/imagewithbasebath";
import Select from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ProductPOS from "./products";
import Staging from "./staging";
import Summary from "./summary";
// import Fee from "./fee";
import Receipt from "./receipt";
import CashPayment from "./modal/CashPayment";
import SizeOption from "./modal/SizeOption";
// import PaymentMethod from "./payment";
import CompletedPayment from "./modal/CompletedPayment";
import GrandTotal from "./grand-total";
import Header from "./header";
import { useDispatch } from "react-redux";
import { getProduct } from "src/redux/store/product";
import { useAuth } from "src/hooks/useAuth";
import Transaction from "./modal/Transaction";
import ReprintReceipt from "./receipt/re-print";
// import CardPayment from "./modal/CardPayment";
import PaymentMethodModal from "./modal/PaymentMethod";
import PaymentButton from "./payment/PaymentButton";
import CardPayment from "./modal/CardPayment";

const Pos = () => {
  // const product = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    dispatch(getProduct());
  }, [dispatch]);

  const tax = [
    { value: "exclusive", label: "Exclusive" },
    { value: "inclusive", label: "Inclusive" },
  ];
  const discounttype = [
    { value: "percentage", label: "Percentage" },
    { value: "earlyPaymentDiscounts", label: "Early payment discounts" },
  ];
  const units = [
    { value: "kilogram", label: "Kilogram" },
    { value: "grams", label: "Grams" },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <div>
      <div className="page-wrapper pos-pg-wrapper ms-0">
        <div className="content pos-design p-0">
          <div className="row align-items-start pos-wrapper">
            <div className="col-md-12 col-lg-8">
              <div className="pos-categories tabs_wrapper">
                <Header />
                {/* <h5>Categories</h5>
                <p>Select From Below Categories</p>
                <SliderPOS /> */}
                <div className="pos-products">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-3">Products</h5>
                  </div>
                  <ProductPOS />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 ps-0">
              <aside className="product-order-list">
                {/* <div className="head d-flex align-items-center justify-content-between w-100">
                  <div className="">
                    <h5>Order List</h5>
                    <span>Transaction ID : #65565</span>
                  </div>
                  <div className="">
                    <Link className="confirm-text" to="#">
                      <Trash2 className="feather-16 text-danger me-1" />
                    </Link>
                    <Link to="#" className="text-default">
                      <MoreVertical className="feather-16" />
                    </Link>
                  </div>
                </div> */}
                {/* <Customer /> */}
                <Staging />
                <div className="block-section">
                  {/* <Fee /> */}
                  <Summary />
                </div>
                <GrandTotal />
                <PaymentButton />
                {/* <PaymentMethod /> */}
                {/* <ActionButton /> */}
              </aside>
            </div>
          </div>
        </div>
      </div>

      {/* Products */}
      <div
        className="modal fade modal-default pos-modal"
        id="products"
        aria-labelledby="products"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-4 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h5 className="me-4">Products</h5>
                <span className="badge bg-info d-inline-block mb-0">
                  Order ID : #666614
                </span>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-4">
              <form>
                <div className="product-wrap">
                  <div className="product-list d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center flex-fill">
                      <Link to="#" className="img-bg me-2">
                        <ImageWithBasePath
                          src="assets/img/products/pos-product-16.png"
                          alt="Products"
                        />
                      </Link>
                      <div className="info d-flex align-items-center justify-content-between flex-fill">
                        <div>
                          <span>PT0005</span>
                          <h6>
                            <Link to="#">Red Nike Laser</Link>
                          </h6>
                        </div>
                        <p>$2000</p>
                      </div>
                    </div>
                  </div>
                  <div className="product-list d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center flex-fill">
                      <Link to="#" className="img-bg me-2">
                        <ImageWithBasePath
                          src="assets/img/products/pos-product-17.png"
                          alt="Products"
                        />
                      </Link>
                      <div className="info d-flex align-items-center justify-content-between flex-fill">
                        <div>
                          <span>PT0235</span>
                          <h6>
                            <Link to="#">Iphone 14</Link>
                          </h6>
                        </div>
                        <p>$3000</p>
                      </div>
                    </div>
                  </div>
                  <div className="product-list d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center flex-fill">
                      <Link to="#" className="img-bg me-2">
                        <ImageWithBasePath
                          src="assets/img/products/pos-product-16.png"
                          alt="Products"
                        />
                      </Link>
                      <div className="info d-flex align-items-center justify-content-between flex-fill">
                        <div>
                          <span>PT0005</span>
                          <h6>
                            <Link to="#">Red Nike Laser</Link>
                          </h6>
                        </div>
                        <p>$2000</p>
                      </div>
                    </div>
                  </div>
                  <div className="product-list d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center flex-fill">
                      <Link to="#" className="img-bg me-2">
                        <ImageWithBasePath
                          src="assets/img/products/pos-product-17.png"
                          alt="Products"
                        />
                      </Link>
                      <div className="info d-flex align-items-center justify-content-between flex-fill">
                        <div>
                          <span>PT0005</span>
                          <h6>
                            <Link to="#">Red Nike Laser</Link>
                          </h6>
                        </div>
                        <p>$2000</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-sm-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <Link to="#" className="btn btn-primary">
                    Submit
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Products */}
      <div
        className="modal fade"
        id="create"
        tabIndex={-1}
        aria-labelledby="create"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks">
                      <label>Customer Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks">
                      <label>Email</label>
                      <input type="email" className="form-control" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks">
                      <label>Phone</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks">
                      <label>Country</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks">
                      <label>City</label>
                      <input type="text" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks">
                      <label>Address</label>
                      <input type="text" />
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-sm-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-cancel"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <Link to="#" className="btn btn-submit me-2">
                    Submit
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Hold */}
      <div
        className="modal fade modal-default pos-modal"
        id="hold-order"
        aria-labelledby="hold-order"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-4">
              <h5>Hold order</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-4">
              <form>
                <h2 className="text-center p-4">4500.00</h2>
                <div className="input-block">
                  <label>Order Reference</label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue=""
                    placeholder=""
                  />
                </div>
                <p>
                  The current order will be set on hold. You can retreive this
                  order from the pending order button. Providing a reference to
                  it might help you to identify the order more quickly.
                </p>
                <div className="modal-footer d-sm-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <Link to="#" className="btn btn-primary">
                    Confirm
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Hold */}
      {/* Edit Product */}
      <div
        className="modal fade modal-default pos-modal"
        id="edit-product"
        aria-labelledby="edit-product"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-4">
              <h5>Red Nike Laser</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-4">
              <form>
                <div className="row">
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks add-product">
                      <label>
                        Product Name <span>*</span>
                      </label>
                      <input type="text" placeholder={45} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks add-product">
                      <label>
                        Tax Type <span>*</span>
                      </label>
                      <Select
                        classNamePrefix="react-select"
                        options={tax}
                        placeholder="Select Option"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks add-product">
                      <label>
                        Tax <span>*</span>
                      </label>
                      <input type="text" placeholder="% 15" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks add-product">
                      <label>
                        Discount Type <span>*</span>
                      </label>
                      <Select
                        classNamePrefix="react-select"
                        options={discounttype}
                        placeholder="Select Option"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks add-product">
                      <label>
                        Discount <span>*</span>
                      </label>
                      <input type="text" placeholder={15} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks add-product">
                      <label>
                        Sale Unit <span>*</span>
                      </label>
                      <Select
                        classNamePrefix="react-select"
                        options={units}
                        placeholder="Select Option"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-sm-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <Link to="#" className="btn btn-primary">
                    Submit
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Product */}
      {/* Recent Transactions */}
      <div
        className="modal fade pos-modal"
        id="recents"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header p-4">
              <h5 className="modal-title">Recent Transactions</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-4">
              <div className="tabs-sets">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="purchase-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#purchase"
                      type="button"
                      aria-controls="purchase"
                      aria-selected="true"
                      role="tab"
                    >
                      Purchase
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="payment-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#payment"
                      type="button"
                      aria-controls="payment"
                      aria-selected="false"
                      role="tab"
                    >
                      Payment
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="return-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#return"
                      type="button"
                      aria-controls="return"
                      aria-selected="false"
                      role="tab"
                    >
                      Return
                    </button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="purchase"
                    role="tabpanel"
                    aria-labelledby="purchase-tab"
                  >
                    <div className="table-top">
                      <div className="search-set">
                        <div className="search-input">
                          <input
                            type="text"
                            placeholder="Search"
                            className="form-control form-control-sm formsearch"
                          />
                          <Link to className="btn btn-searchset">
                            <i
                              data-feather="search"
                              className="feather-search"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="wordset">
                        <ul>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip}
                            >
                              <Link>
                                <ImageWithBasePath
                                  src="assets/img/icons/pdf.svg"
                                  alt="img"
                                />
                              </Link>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={renderExcelTooltip}
                            >
                              <Link
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                              >
                                <ImageWithBasePath
                                  src="assets/img/icons/excel.svg"
                                  alt="img"
                                />
                              </Link>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={renderPrinterTooltip}
                            >
                              <Link
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                              >
                                <i
                                  data-feather="printer"
                                  className="feather-printer"
                                />
                              </Link>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table datanew">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Reference</th>
                            <th>Customer</th>
                            <th>Amount </th>
                            <th className="no-sort">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0101</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0102</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0103</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0104</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0105</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0106</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0107</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="payment" role="tabpanel">
                    <div className="table-top">
                      <div className="search-set">
                        <div className="search-input">
                          <input
                            type="text"
                            placeholder="Search"
                            className="form-control form-control-sm formsearch"
                          />
                          <Link to className="btn btn-searchset">
                            <i
                              data-feather="search"
                              className="feather-search"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="wordset">
                        <ul>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip}
                            >
                              <Link>
                                <ImageWithBasePath
                                  src="assets/img/icons/pdf.svg"
                                  alt="img"
                                />
                              </Link>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={renderExcelTooltip}
                            >
                              <Link
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                              >
                                <ImageWithBasePath
                                  src="assets/img/icons/excel.svg"
                                  alt="img"
                                />
                              </Link>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={renderPrinterTooltip}
                            >
                              <Link
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                              >
                                <i
                                  data-feather="printer"
                                  className="feather-printer"
                                />
                              </Link>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table datanew">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Reference</th>
                            <th>Customer</th>
                            <th>Amount </th>
                            <th className="no-sort">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0101</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0102</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0103</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0104</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0105</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0106</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0107</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="return" role="tabpanel">
                    <div className="table-top">
                      <div className="search-set">
                        <div className="search-input">
                          <input
                            type="text"
                            placeholder="Search"
                            className="form-control form-control-sm formsearch"
                          />
                          <Link to className="btn btn-searchset">
                            <i
                              data-feather="search"
                              className="feather-search"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="wordset">
                        <ul>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip}
                            >
                              <Link>
                                <ImageWithBasePath
                                  src="assets/img/icons/pdf.svg"
                                  alt="img"
                                />
                              </Link>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={renderExcelTooltip}
                            >
                              <Link
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                              >
                                <ImageWithBasePath
                                  src="assets/img/icons/excel.svg"
                                  alt="img"
                                />
                              </Link>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={renderPrinterTooltip}
                            >
                              <Link
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                              >
                                <i
                                  data-feather="printer"
                                  className="feather-printer"
                                />
                              </Link>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table datanew">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Reference</th>
                            <th>Customer</th>
                            <th>Amount </th>
                            <th className="no-sort">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0101</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0102</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0103</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0104</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0105</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0106</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jan 2023</td>
                            <td>INV/SL0107</td>
                            <td>Walk-in Customer</td>
                            <td>$1500.00</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="eye"
                                    className="feather-eye"
                                  />
                                </Link>
                                <Link className="me-2 p-2" to="#">
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  onClick={showConfirmationAlert}
                                  className="p-2 confirm-text"
                                  to="#"
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Recent Transactions */}

      {/* Recent Transactions */}
      <div
        className="modal fade pos-modal"
        id="orders"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header p-4">
              <h5 className="modal-title">Orders</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-4">
              <div className="tabs-sets">
                <ul className="nav nav-tabs" id="myTabs" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="onhold-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#onhold"
                      type="button"
                      aria-controls="onhold"
                      aria-selected="true"
                      role="tab"
                    >
                      Onhold
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="unpaid-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#unpaid"
                      type="button"
                      aria-controls="unpaid"
                      aria-selected="false"
                      role="tab"
                    >
                      Unpaid
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="paid-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#paid"
                      type="button"
                      aria-controls="paid"
                      aria-selected="false"
                      role="tab"
                    >
                      Paid
                    </button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="onhold"
                    role="tabpanel"
                    aria-labelledby="onhold-tab"
                  >
                    <div className="table-top">
                      <div className="search-set w-100 search-order">
                        <div className="search-input w-100">
                          <input
                            type="text"
                            placeholder="Search"
                            className="form-control form-control-sm formsearch w-100"
                          />
                          <Link to className="btn btn-searchset">
                            <i
                              data-feather="search"
                              className="feather-search"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="order-body">
                      <div className="default-cover p-4 mb-4">
                        <span className="badge bg-secondary d-inline-block mb-4">
                          Order ID : #666659
                        </span>
                        <div className="row">
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr className="mb-3">
                                  <td>Cashier</td>
                                  <td className="colon">:</td>
                                  <td className="text">admin</td>
                                </tr>
                                <tr>
                                  <td>Customer</td>
                                  <td className="colon">:</td>
                                  <td className="text">Botsford</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total</td>
                                  <td className="colon">:</td>
                                  <td className="text">$900</td>
                                </tr>
                                <tr>
                                  <td>Date</td>
                                  <td className="colon">:</td>
                                  <td className="text">29-08-2023 13:39:11</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <p className="p-4">
                          Customer need to recheck the product once
                        </p>
                        <div className="btn-row d-sm-flex align-items-center justify-content-between">
                          <Link
                            to="#"
                            className="btn btn-info btn-icon flex-fill"
                          >
                            Open
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-danger btn-icon flex-fill"
                          >
                            Products
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-success btn-icon flex-fill"
                          >
                            Print
                          </Link>
                        </div>
                      </div>
                      <div className="default-cover p-4 mb-4">
                        <span className="badge bg-secondary d-inline-block mb-4">
                          Order ID : #666660
                        </span>
                        <div className="row">
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr className="mb-3">
                                  <td>Cashier</td>
                                  <td className="colon">:</td>
                                  <td className="text">admin</td>
                                </tr>
                                <tr>
                                  <td>Customer</td>
                                  <td className="colon">:</td>
                                  <td className="text">Smith</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total</td>
                                  <td className="colon">:</td>
                                  <td className="text">$15000</td>
                                </tr>
                                <tr>
                                  <td>Date</td>
                                  <td className="colon">:</td>
                                  <td className="text">30-08-2023 15:59:11</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <p className="p-4">
                          Customer need to recheck the product once
                        </p>
                        <div className="btn-row d-flex align-items-center justify-content-between">
                          <Link
                            to="#"
                            className="btn btn-info btn-icon flex-fill"
                          >
                            Open
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-danger btn-icon flex-fill"
                          >
                            Products
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-success btn-icon flex-fill"
                          >
                            Print
                          </Link>
                        </div>
                      </div>
                      <div className="default-cover p-4">
                        <span className="badge bg-secondary d-inline-block mb-4">
                          Order ID : #666661
                        </span>
                        <div className="row">
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr className="mb-3">
                                  <td>Cashier</td>
                                  <td className="colon">:</td>
                                  <td className="text">admin</td>
                                </tr>
                                <tr>
                                  <td>Customer</td>
                                  <td className="colon">:</td>
                                  <td className="text">John David</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total</td>
                                  <td className="colon">:</td>
                                  <td className="text">$2000</td>
                                </tr>
                                <tr>
                                  <td>Date</td>
                                  <td className="colon">:</td>
                                  <td className="text">01-09-2023 13:15:00</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <p className="p-4 mb-4">
                          Customer need to recheck the product once
                        </p>
                        <div className="btn-row d-flex align-items-center justify-content-between">
                          <Link
                            to="#"
                            className="btn btn-info btn-icon flex-fill"
                          >
                            Open
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-danger btn-icon flex-fill"
                          >
                            Products
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-success btn-icon flex-fill"
                          >
                            Print
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="unpaid" role="tabpanel">
                    <div className="table-top">
                      <div className="search-set w-100 search-order">
                        <div className="search-input w-100">
                          <input
                            type="text"
                            placeholder="Search"
                            className="form-control form-control-sm formsearch w-100"
                          />
                          <Link to className="btn btn-searchset">
                            <i
                              data-feather="search"
                              className="feather-search"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="order-body">
                      <div className="default-cover p-4 mb-4">
                        <span className="badge bg-info d-inline-block mb-4">
                          Order ID : #666662
                        </span>
                        <div className="row">
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr className="mb-3">
                                  <td>Cashier</td>
                                  <td className="colon">:</td>
                                  <td className="text">admin</td>
                                </tr>
                                <tr>
                                  <td>Customer</td>
                                  <td className="colon">:</td>
                                  <td className="text">Anastasia</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total</td>
                                  <td className="colon">:</td>
                                  <td className="text">$2500</td>
                                </tr>
                                <tr>
                                  <td>Date</td>
                                  <td className="colon">:</td>
                                  <td className="text">10-09-2023 17:15:11</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <p className="p-4">
                          Customer need to recheck the product once
                        </p>
                        <div className="btn-row d-flex align-items-center justify-content-between">
                          <Link
                            to="#"
                            className="btn btn-info btn-icon flex-fill"
                          >
                            Open
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-danger btn-icon flex-fill"
                          >
                            Products
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-success btn-icon flex-fill"
                          >
                            Print
                          </Link>
                        </div>
                      </div>
                      <div className="default-cover p-4 mb-4">
                        <span className="badge bg-info d-inline-block mb-4">
                          Order ID : #666663
                        </span>
                        <div className="row">
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr className="mb-3">
                                  <td>Cashier</td>
                                  <td className="colon">:</td>
                                  <td className="text">admin</td>
                                </tr>
                                <tr>
                                  <td>Customer</td>
                                  <td className="colon">:</td>
                                  <td className="text">Lucia</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total</td>
                                  <td className="colon">:</td>
                                  <td className="text">$1500</td>
                                </tr>
                                <tr>
                                  <td>Date</td>
                                  <td className="colon">:</td>
                                  <td className="text">11-09-2023 14:50:11</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <p className="p-4">
                          Customer need to recheck the product once
                        </p>
                        <div className="btn-row d-flex align-items-center justify-content-between">
                          <Link
                            to="#"
                            className="btn btn-info btn-icon flex-fill"
                          >
                            Open
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-danger btn-icon flex-fill"
                          >
                            Products
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-success btn-icon flex-fill"
                          >
                            Print
                          </Link>
                        </div>
                      </div>
                      <div className="default-cover p-4 mb-4">
                        <span className="badge bg-info d-inline-block mb-4">
                          Order ID : #666664
                        </span>
                        <div className="row">
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr className="mb-3">
                                  <td>Cashier</td>
                                  <td className="colon">:</td>
                                  <td className="text">admin</td>
                                </tr>
                                <tr>
                                  <td>Customer</td>
                                  <td className="colon">:</td>
                                  <td className="text">Diego</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total</td>
                                  <td className="colon">:</td>
                                  <td className="text">$30000</td>
                                </tr>
                                <tr>
                                  <td>Date</td>
                                  <td className="colon">:</td>
                                  <td className="text">12-09-2023 17:22:11</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <p className="p-4 mb-4">
                          Customer need to recheck the product once
                        </p>
                        <div className="btn-row d-flex align-items-center justify-content-between">
                          <Link
                            to="#"
                            className="btn btn-info btn-icon flex-fill"
                          >
                            Open
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-danger btn-icon flex-fill"
                          >
                            Products
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-success btn-icon flex-fill"
                          >
                            Print
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="paid" role="tabpanel">
                    <div className="table-top">
                      <div className="search-set w-100 search-order">
                        <div className="search-input w-100">
                          <input
                            type="text"
                            placeholder="Search"
                            className="form-control form-control-sm formsearch w-100"
                          />
                          <Link to className="btn btn-searchset">
                            <i
                              data-feather="search"
                              className="feather-search"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="order-body">
                      <div className="default-cover p-4 mb-4">
                        <span className="badge bg-primary d-inline-block mb-4">
                          Order ID : #666665
                        </span>
                        <div className="row">
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr className="mb-3">
                                  <td>Cashier</td>
                                  <td className="colon">:</td>
                                  <td className="text">admin</td>
                                </tr>
                                <tr>
                                  <td>Customer</td>
                                  <td className="colon">:</td>
                                  <td className="text">Hugo</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total</td>
                                  <td className="colon">:</td>
                                  <td className="text">$5000</td>
                                </tr>
                                <tr>
                                  <td>Date</td>
                                  <td className="colon">:</td>
                                  <td className="text">13-09-2023 19:39:11</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <p className="p-4">
                          Customer need to recheck the product once
                        </p>
                        <div className="btn-row d-flex align-items-center justify-content-between">
                          <Link
                            to="#"
                            className="btn btn-info btn-icon flex-fill"
                          >
                            Open
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-danger btn-icon flex-fill"
                          >
                            Products
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-success btn-icon flex-fill"
                          >
                            Print
                          </Link>
                        </div>
                      </div>
                      <div className="default-cover p-4 mb-4">
                        <span className="badge bg-primary d-inline-block mb-4">
                          Order ID : #666666
                        </span>
                        <div className="row">
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr className="mb-3">
                                  <td>Cashier</td>
                                  <td className="colon">:</td>
                                  <td className="text">admin</td>
                                </tr>
                                <tr>
                                  <td>Customer</td>
                                  <td className="colon">:</td>
                                  <td className="text">Antonio</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total</td>
                                  <td className="colon">:</td>
                                  <td className="text">$7000</td>
                                </tr>
                                <tr>
                                  <td>Date</td>
                                  <td className="colon">:</td>
                                  <td className="text">15-09-2023 18:39:11</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <p className="p-4">
                          Customer need to recheck the product once
                        </p>
                        <div className="btn-row d-flex align-items-center justify-content-between">
                          <Link
                            to="#"
                            className="btn btn-info btn-icon flex-fill"
                          >
                            Open
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-danger btn-icon flex-fill"
                          >
                            Products
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-success btn-icon flex-fill"
                          >
                            Print
                          </Link>
                        </div>
                      </div>
                      <div className="default-cover p-4 mb-4">
                        <span className="badge bg-primary d-inline-block mb-4">
                          Order ID : #666667
                        </span>
                        <div className="row">
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr className="mb-3">
                                  <td>Cashier</td>
                                  <td className="colon">:</td>
                                  <td className="text">admin</td>
                                </tr>
                                <tr>
                                  <td>Customer</td>
                                  <td className="colon">:</td>
                                  <td className="text">MacQuoid</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-sm-12 col-md-6 record mb-3">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total</td>
                                  <td className="colon">:</td>
                                  <td className="text">$7050</td>
                                </tr>
                                <tr>
                                  <td>Date</td>
                                  <td className="colon">:</td>
                                  <td className="text">17-09-2023 19:39:11</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <p className="p-4 mb-4">
                          Customer need to recheck the product once
                        </p>
                        <div className="btn-row d-flex align-items-center justify-content-between">
                          <Link
                            to="#"
                            className="btn btn-info btn-icon flex-fill"
                          >
                            Open
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-danger btn-icon flex-fill"
                          >
                            Products
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-success btn-icon flex-fill"
                          >
                            Print
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Recent Transactions */}

      <Receipt />
      <CashPayment />
      <CardPayment />
      <PaymentMethodModal />
      <SizeOption />
      <CompletedPayment />
      <Transaction />
      <ReprintReceipt />
    </div>
  );
};

export default Pos;
