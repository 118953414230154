import React from "react";
import { Modal } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
import { finishOrder } from "src/redux/store/pos";
// import ImageWithBasePath from "src/core/img/imagewithbasebath";
// import { Link } from "react-router-dom";
// import { rupiahCurrency } from "src/helper/currency";
import PrintableComponent from "./PrintableComponent";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { printReceipt } from "src/helper/print";

const Receipt = () => {
  const pos = useSelector((state) => state.pos);
  // const [selectedSize, setSelectedSize] = useState(null);
  const dispatch = useDispatch();
  // const dispatch = useDispatch();
  // const [data, setData] = useState({
  //   name: "",
  //   email: "",
  // });
  const handleSubmit = (e) => {
    e.preventDefault();
    // alert("submitted");
    // dispatch(createUser(data));
    // setOpen(false);
  };

  const handlePrint = () => {
    printReceipt("print-receipts");
    dispatch(finishOrder());
  };

  return (
    <Modal
      id="print-receipt"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={pos.modalPrintReceipt}
    >
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <PrintableComponent pos={pos} />

          <div className="d-flex justify-content-center my-3">
            <div
              to="#"
              className="btn btn-primary non-printable"
              role="button"
              onClick={handlePrint}
            >
              Print Receipt
            </div>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
};

export default Receipt;
