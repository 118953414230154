// src/features/posSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "src/config/axios";
import { getProduct } from "./product";
import { calculateDiscount } from "src/helper/currency";
import { getTransactionDetail } from "./transaction";
import Swal from "sweetalert2";

const initialState = {
  items: [],
  totalAmount: 0,
  totalDiscount: 0,
  totalAfterDiscount: 0,
  gst: 0,
  shipping: 0,
  discount: 0,
  discountValue: 0,
  // cash
  cash: 0,
  change: 0,
  // cash
  // card
  refCode: null,
  cardNumber: null,
  // card
  paymentType: null,
  date: null,
  note: "",
  selectedItem: {
    // image: [],
  },
  modalSize: false,
  modalCashPayment: false,
  modalCardPayment: false,
  modalCompletedPayment: false,
  modalPaymentMethod: false,
  modalPrintReceipt: false,
  loadingTransaction: false,
  statusTransaction: false,
};

export const submitTransaction = createAsyncThunk(
  "create-transaction",
  async (data, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/sales-invoice`,
        { ...data, warehouse_id: user.warehouse_id },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      dispatch(
        getTransactionDetail({ billing_id: response.data.data.billing_id })
      );
      dispatch(getProduct());
      dispatch(toggleModalCompletedPayment());
      return {
        data: response.data.data,
        date: data.txtSalesDate,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const scanProduct = createAsyncThunk(
  "scan-product",
  async (data, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/scan`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("resp ", response);
      const result = response.data;

      if (result.data.length > 0) {
        if (result.data[0].stock > 0) {
          dispatch(
            addProduct({
              itemProductId: result.data[0].product_id,
              assets: result.data[0].images[0],
              product_title: result.data[0].product_alias,
              cat_title: result.data[0].product_size_code,
              itemPrice: parseFloat(result.data[0].product_price),
              itemQty: parseInt(1),
              itemProductSize: result.data[0].product_size,
              itemDisc: result.data[0].product_discount,
              itemDiscAmount: calculateDiscount(
                parseFloat(result.data[0].product_price),
                result.data[0].product_discount
              ).discAmount,
              maxQty: result.data[0].stock,
            })
          );
        }
      }

      return {
        data: result.data,
      };
      // dispatch(
      //   getTransactionDetail({ billing_id: response.data.data.billing_id })
      // );
      // dispatch(getProduct());
      // dispatch(toggleModalCompletedPayment());
      // return {
      //   data: response.data.data,
      //   date: data.txtSalesDate,
      // };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

const posSlice = createSlice({
  name: "pos",
  initialState,
  reducers: {
    selectProduct: (state, action) => {
      state.modalSize = true;
      state.selectedItem = action.payload;
    },
    addProduct: (state, action) => {
      //   state.items.push(action.payload);
      //   state.totalAmount += action.payload.price * action.payload.quantity;
      const existingProduct = state.items.find(
        (item) =>
          item.itemProductId === action.payload.itemProductId &&
          item.itemProductSize === action.payload.itemProductSize
      );
      if (existingProduct) {
        existingProduct.itemQty += action.payload.itemQty;
        existingProduct.itemDiscAmount += action.payload.itemDiscAmount;
        const totalAmount = action.payload.itemPrice * action.payload.itemQty;
        state.totalAmount += totalAmount;
        state.totalDiscount += calculateDiscount(
          totalAmount,
          action.payload.itemDisc
        ).discAmount;
      } else {
        state.items.push(action.payload);
        const totalAmount = action.payload.itemPrice * action.payload.itemQty;
        state.totalAmount += totalAmount;
        state.totalDiscount += calculateDiscount(
          totalAmount,
          action.payload.itemDisc
        ).discAmount;
      }
      state.selectedItem = {};
    },
    removeProduct: (state, action) => {
      const index = state.items.findIndex(
        (item) =>
          item.itemProductId === action.payload.itemProductId &&
          item.itemProductSize === action.payload.itemProductSize
      );
      if (index !== -1) {
        const totalAmount =
          state.items[index].itemPrice * state.items[index].itemQty;
        state.totalAmount -= totalAmount;
        state.totalDiscount -= calculateDiscount(
          totalAmount,
          action.payload.itemDisc
        ).discAmount;
        state.items.splice(index, 1);
      }
    },
    removeAllProducts: (state) => {
      state.items = [];
      state.totalAmount = 0;
      state.totalDiscount = 0;
    },
    incrementQuantity: (state, action) => {
      const item = state.items.find(
        (item) =>
          item.itemProductId === action.payload.itemProductId &&
          item.itemProductSize === action.payload.itemProductSize
      );
      if (item) {
        item.itemQty += 1;
        item.itemDiscAmount += action.payload.itemDiscAmount;
        const totalAmount = item.itemPrice;
        state.totalAmount += totalAmount;
        state.totalDiscount += calculateDiscount(
          totalAmount,
          action.payload.itemDisc
        ).discAmount;
      }
    },
    decrementQuantity: (state, action) => {
      const item = state.items.find(
        (item) =>
          item.itemProductId === action.payload.itemProductId &&
          item.itemProductSize === action.payload.itemProductSize
      );
      if (item && item.itemQty > 1) {
        item.itemQty -= 1;
        item.itemDiscAmount -= action.payload.itemDiscAmount;
        const totalAmount = item.itemPrice;
        state.totalAmount -= totalAmount;
        state.totalDiscount -= calculateDiscount(
          totalAmount,
          action.payload.itemDisc
        ).discAmount;
      }
    },
    toggleModalSize: (state) => {
      if (state.modalSize) {
        state.selectedItem = {};
      }
      state.modalSize = !state.modalSize;
    },
    toggleModalCashPayment: (state) => {
      if (state.modalCashPayment) {
        state.cash = 0;
        state.change = 0;
      }
      // if (state.items.length > 0) {
      //   state.modalCardPayment = false;
      //   state.paymentType = "CASH";
      //   state.modalCashPayment = !state.modalCashPayment;
      // }
      state.modalPaymentMethod = true;
      state.modalCardPayment = false;
      state.modalCashPayment = false;
    },
    toggleModalCardPayment: (state) => {
      // if (state.items.length > 0) {
      //   state.modalCashPayment = false;
      //   state.paymentType = "CARD";
      //   state.modalCardPayment = !state.modalCardPayment;
      // }
      state.modalPaymentMethod = true;
      state.modalCardPayment = false;
      state.modalCashPayment = false;
    },
    toggleModalCompletedPayment: (state) => {
      state.modalCompletedPayment = !state.modalCompletedPayment;
      if (state.modalCompletedPayment) {
        state.modalCashPayment = false;
        state.modalCardPayment = false;
      }
    },
    toggleModalPaymentMethod: (state) => {
      if (state.modalPaymentMethod) {
        state.paymentType = null;
      }
      state.modalPaymentMethod = !state.modalPaymentMethod;
      // if (state.modalCompletedPayment) {
      //   state.modalCashPayment = false;
      //   state.modalCardPayment = false;
      // }
    },
    toggleSelectedPaymentMethod: (state) => {
      state.modalPaymentMethod = false;
      if (state.paymentType == "CASH RUPIAH") {
        state.modalCashPayment = true;
      } else {
        state.modalCardPayment = true;
      }
      // if (state.modalCompletedPayment) {
      //   state.modalCashPayment = false;
      //   state.modalCardPayment = false;
      // }
    },
    togglePrintReceipt: (state) => {
      if (state.modalPrintReceipt) {
        state.modalCompletedPayment = false;
      }
      state.modalPrintReceipt = !state.modalPrintReceipt;
    },
    setCash: (state, action) => {
      if (action.payload > 0) {
        state.cash = action.payload;
        const change = action.payload - state.totalAmount + state.totalDiscount;
        state.change = change;
      } else {
        state.change = 0;
        state.cash = 0;
      }
    },
    setNote: (state, action) => {
      state.note = action.payload;
    },
    setRefCode: (state, action) => {
      state.refCode = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentType = action.payload;
    },
    setCardNumber: (state, action) => {
      state.cardNumber = action.payload;
    },
    finishOrder: (state) => {
      state.items = [];
      state.totalAmount = 0;
      state.totalDiscount = 0;
      state.gst = 0;
      state.shipping = 0;
      state.discount = 0;
      state.discountValue = 0;
      state.cash = 0;
      state.note = "";
      state.change = 0;
      state.selectedItem = {
        // image: [],
      };
      state.modalSize = false;
      state.modalCashPayment = false;
      state.modalCompletedPayment = false;
      state.modalPrintReceipt = false;
      state.modalPaymentMethod = false;
      state.paymentType = null;
    },
    closeModalCompletedPayment: (state) => {
      state.modalCompletedPayment = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitTransaction.fulfilled, (state, action) => {
      state.loadingTransaction = false;
      state.statusTransaction = true;
      state.modalCardPayment = false;
      state.modalCashPayment = false;
      state.date = action.payload.date;
    });
    builder.addCase(submitTransaction.pending, (state) => {
      state.loadingTransaction = true;
      state.statusTransaction = false;
    });
    builder.addCase(submitTransaction.rejected, (state) => {
      state.loadingTransaction = false;
      state.statusTransaction = false;
    });
    builder.addCase(scanProduct.fulfilled, (state, action) => {
      if (action.payload.data.length > 0) {
        if (action.payload.data[0].stock > 0) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Product Added",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Product is Out of Stock`,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Product Not Found`,
        });
      }
      state.selectedItem = {};
    });
    builder.addCase(scanProduct.pending, (state) => {});
    builder.addCase(scanProduct.rejected, (state, action) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${action.payload.message}`,
      });
      state.selectedItem = {};
    });
  },
});

export const {
  selectProduct,
  addProduct,
  removeProduct,
  removeAllProducts,
  incrementQuantity,
  decrementQuantity,
  toggleModalSize,
  toggleModalCashPayment,
  toggleModalCardPayment,
  toggleModalCompletedPayment,
  togglePrintReceipt,
  toggleModalPaymentMethod,
  finishOrder,
  setCash,
  setNote,
  setCardNumber,
  setRefCode,
  setPaymentMethod,
  toggleSelectedPaymentMethod,
  closeModalCompletedPayment,
} = posSlice.actions;
export default posSlice.reducer;
