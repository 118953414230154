// src/features/warehouseSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "src/config/axios";

const initialState = {
  data: [],
  total: 0,
  params: {},
  loading: false,
};

export const getWarehouse = createAsyncThunk("warehouse", async (params) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/v1/warehouse`,
      {
        warehouse_id: user.warehouse_id,
        warehouse_name: "",
        page: 1,
        limit: 0,
        order: "warehouse_name",
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const result = response.data;
    return {
      data: result.data.data,
      total: result.data.total,
      params,
    };
  } catch (error) {
    console.log(error);
  }
});

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWarehouse.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.params = action.payload.params;
    });
    builder.addCase(getWarehouse.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWarehouse.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default warehouseSlice.reducer;
