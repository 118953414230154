import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleModalPrint } from "src/redux/store/transferStockIn";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Printer } from "react-feather";
import { Divider } from "antd";
import { printLetter } from "src/helper/print";

const PrintModal = () => {
  const transferStockIn = useSelector((state) => state.transferStockIn);
  const dispatch = useDispatch();

  const handlePrint = () => {
    printLetter("skb");
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transferStockIn.modalPrint}
      onHide={() => dispatch(toggleModalPrint())}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title>Preview Print</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body id="skb" className="p-5">
        <div className="row my-3">
          <div className="col-6">
            <div className="d-flex flex-column gap-2">
              <div>No: [no_ref]</div>
              <div>Field: [field_name]</div>
            </div>
          </div>
          <div className="col-6">
            <div className="d-flex flex-column gap-2">
              <div>No: [no_ref]</div>
              <div>Field: [field_name]</div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered mb-0">
            <thead>
              <tr>
                <th>No</th>
                <th>Product Code</th>
                <th>Product Name</th>
                <th>Size</th>
                <th>Qty</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {transferStockIn.detail.data.map((item, key) => (
                <tr key={key}>
                  <td className="padding-fix">{key + 1}</td>
                  <td></td>
                  <td className="padding-fix">{item.product_alias}</td>
                  <td></td>
                  <td>{item.qty}</td>
                  <td>{item.stock_order_detail_note}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div
          className="d-flex justify-content-end my-3"
          style={{ marginRight: 100 }}
        >
          <div>Tanggal: </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column" style={{ marginLeft: 100 }}>
            <div style={{ paddingBottom: 100 }}>Penerima, </div>
            <Divider style={{ borderColor: "#101010" }} />
          </div>

          <div className="d-flex flex-column" style={{ marginRight: 100 }}>
            <div style={{ paddingBottom: 100 }}>Pengirim, </div>
            <Divider style={{ borderColor: "#101010" }} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex align-items-center justify-content-end gap-2">
          <button
            className="btn btn-secondary"
            onClick={() => dispatch(toggleModalPrint())}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary d-flex align-items-center justify-content-center"
            onClick={handlePrint}
          >
            <Printer size={18} />
            <span className="ms-2">Print</span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintModal;
