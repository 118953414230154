import React from "react";
import { CreditCard } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { toggleModalPaymentMethod } from "src/redux/store/pos";

const PaymentButton = () => {
  const pos = useSelector((state) => state.pos);
  const dispatch = useDispatch();
  return (
    <div className="d-grid">
      <button
        className="btn btn-primary btn-lg d-flex align-items-center justify-content-center"
        disabled={pos.items.length == 0}
        onClick={() => dispatch(toggleModalPaymentMethod())}
      >
        <CreditCard />
        <span className="ms-2 fw-bold">Payment</span>
      </button>
    </div>
  );
};

export default PaymentButton;
