// src/features/userSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "src/config/axios";

const initialState = {
  data: [],
  total: 0,
  params: {},
  loading: false,
};

export const getUser = createAsyncThunk("user", async (params) => {
  try {
    let url = `https://dummyjson.com/users?limit=${params.limit}&skip=${params.skip}&select=firstName,email,role,image,phone`;
    if (params.filter) {
      url = `https://dummyjson.com/users/search?q=${params.filter}`;
    }
    const response = await axios.get(url);
    // const result = response.data;
    // return result;
    return {
      data: response.data.users,
      total: response.data.total,
      params,
    };
  } catch (error) {
    console.log(error);
  }
});

export const createUser = createAsyncThunk(
  "user/create",
  async (data, { dispatch, getState }) => {
    try {
      const response = await axios.post(
        `https://dummyjson.com/users/add`,
        {
          data,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      await dispatch(getUser(getState().user.params));
      const result = response.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.params = action.payload.params;
      state.loading = false;
    });
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUser.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default userSlice.reducer;
