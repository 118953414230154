// src/features/productSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "src/config/axios";

const initialState = {
  data: [],
  total: 0,
  params: {},
  loading: false,
};

export const getProduct = createAsyncThunk(
  "products",
  async (_, { rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/product`,
        {
          warehouse_id: user.warehouse_id,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return {
        data: response.data.data,
      };
    } catch (error) {
      return rejectWithValue(error);
      // console.log(error);
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProduct.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProduct.rejected, (state) => {
      state.loading = false;
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
    });
  },
});

export default productSlice.reducer;
