import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import ImageWithBasePath from "src/core/img/imagewithbasebath";
import //   toggleModalCashPayment,
// toggleModalCompletedPayment,
"src/redux/store/pos";
import {
  getTransaction,
  getTransactionDetail,
  toggleModalReprint,
  toggleModalTransaction,
} from "src/redux/store/transaction";
import Table from "src/core/pagination/datatable";
import "./modal.css";
import { rupiahCurrency } from "src/helper/currency";
import { Printer } from "react-feather";
// import warehouse from "src/redux/store/warehouse";

const renderStatus = (status) => {
  if (status == 1) return <span className="badge bg-success">Paid</span>;
};

const renderType = (type) => {
  return <span className="badge bg-secondary">{type}</span>;
};

const Transaction = () => {
  const transaction = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [q, setQ] = useState("");
  const [search, setSearch] = useState("");

  const handle = () => {
    setLimit(10);
    setPage(10);
  };

  useEffect(() => {
    if (transaction.modalTransaction) {
      dispatch(
        getTransaction({
          limit,
          offset: skip,
          billing_code: q,
          order: "billing_id",
        })
      );
    }
  }, [transaction.modalTransaction, limit, skip, q, dispatch]);

  const renderPrintStruck = (props) => (
    <Tooltip id="pdf-tooltip" {...props} onClick={handle}>
      Print Struck
    </Tooltip>
  );

  const columns = [
    {
      title: "#",
      key: "index",
      render: (value, item, index) => (page - 1) * limit + index + 1,
    },
    {
      title: "Billing Code",
      dataIndex: "billing_code",
      sorter: (a, b) => a.billing_code.localeCompare(b.billing_code),
    },
    {
      title: "Billing Date",
      dataIndex: "billing_date",
      //   render: (value, item, index) => (page - 1) * limit + index + 1,
    },
    {
      title: "Status",
      dataIndex: "billing_status",
      render: (value, item, index) => {
        return <span>{renderStatus(item.billing_status)}</span>;
      },
    },
    {
      title: "Payment Type",
      dataIndex: "billing_paid_type",
      render: (value, item, index) => {
        return <span>{renderType(item.billing_paid_type)}</span>;
      },
    },
    {
      title: "Amount Paid",
      dataIndex: "billing_paid",
      render: (value, item, index) => {
        return <span>{rupiahCurrency.format(item.billing_paid)}</span>;
      },
      sorter: (a, b) => a.billing_paid - b.billing_paid,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      render: (value, item, index) => {
        return (
          <span className="text-center">
            {item.discount > 0 ? rupiahCurrency.format(item.discount) : `-`}
          </span>
        );
      },
      sorter: (a, b) => a.discount - b.discount,
    },
    {
      title: "Grand Total",
      dataIndex: "grandtotal",
      render: (value, item, index) => {
        return (
          <span>{rupiahCurrency.format(item.aggregated_data.grandtotal)}</span>
        );
      },
      sorter: (a, b) => a.grandtotal - b.grandtotal,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (value, item, index) => {
        return (
          <OverlayTrigger placement="top" overlay={renderPrintStruck}>
            <div className="action-table-data">
              <div className="edit-delete-action">
                <div className="input-block add-lists"></div>

                <a
                  role="button"
                  className="me-2 p-2"
                  onClick={() => {
                    dispatch(
                      getTransactionDetail({
                        billing_id: item.billing_id,
                        // warehouse_name: item.warehouse_name,
                        // billing_code: item.billing_code,
                        // billing_date: item.billing_date,
                        // billing_paid: item.billing_paid,
                        // billing_paid_type: item.billing_paid_type,
                        // discount: item.aggregated_data.discount,
                        // subtotal: item.aggregated_data.subtotal,
                        // grandtotal: item.aggregated_data.grandtotal,
                      })
                    );
                    dispatch(toggleModalReprint());
                  }}
                >
                  <Printer className="feather-edit" />
                </a>
              </div>
            </div>
          </OverlayTrigger>
        );
      },
    },
  ];

  // const renderTooltip = (props) => (
  //   <Tooltip id="pdf-tooltip" {...props} onClick={handle}>
  //     Pdf
  //   </Tooltip>
  // );
  // const renderExcelTooltip = (props) => (
  //   <Tooltip id="excel-tooltip" {...props}>
  //     Excel
  //   </Tooltip>
  // );
  // const renderPrinterTooltip = (props) => (
  //   <Tooltip id="printer-tooltip" {...props}>
  //     Printer
  //   </Tooltip>
  // );

  const handleChange = (pagination, filters, sorter) => {
    const offset =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;

    setSkip(offset);
    setLimit(limit);
    setPage(pagination.current);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setQ(search);
  };

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transaction.modalTransaction}
      onHide={() => dispatch(toggleModalTransaction())}
    >
      <Modal.Header closeButton>
        <Modal.Title>Transaction List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-sets">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="purchase-tab"
                data-bs-toggle="tab"
                data-bs-target="#purchase"
                type="button"
                aria-controls="purchase"
                aria-selected="true"
                role="tab"
              >
                Purchase
              </button>
            </li>
            {/* <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="payment-tab"
                data-bs-toggle="tab"
                data-bs-target="#payment"
                type="button"
                aria-controls="payment"
                aria-selected="false"
                role="tab"
              >
                Payment
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="return-tab"
                data-bs-toggle="tab"
                data-bs-target="#return"
                type="button"
                aria-controls="return"
                aria-selected="false"
                role="tab"
              >
                Return
              </button>
            </li> */}
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="purchase"
              role="tabpanel"
              aria-labelledby="purchase-tab"
            >
              <div className="table-top">
                <div className="search-set">
                  <form onSubmit={handleSearch}>
                    <div className="search-input">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control form-control-sm formsearch"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <button className="btn btn-searchset" type="submit">
                        <i data-feather="search" className="feather-search" />
                      </button>
                    </div>
                  </form>
                </div>
                {/* <div className="wordset">
                  <ul>
                    <li>
                      <OverlayTrigger placement="top" overlay={renderTooltip}>
                        <Link>
                          <ImageWithBasePath
                            src="assets/img/icons/pdf.svg"
                            alt="img"
                          />
                        </Link>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger
                        placement="top"
                        overlay={renderExcelTooltip}
                      >
                        <Link data-bs-toggle="tooltip" data-bs-placement="top">
                          <ImageWithBasePath
                            src="assets/img/icons/excel.svg"
                            alt="img"
                          />
                        </Link>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger
                        placement="top"
                        overlay={renderPrinterTooltip}
                      >
                        <Link data-bs-toggle="tooltip" data-bs-placement="top">
                          <i
                            data-feather="printer"
                            className="feather-printer"
                          />
                        </Link>
                      </OverlayTrigger>
                    </li>
                  </ul>
                </div> */}
              </div>

              <Table
                tableClassName={"tight-table"}
                columns={columns}
                dataSource={transaction.data}
                loading={transaction.loading}
                total={transaction.total}
                handleChange={handleChange}
              />

              {/* <div className="table-responsive">
                  <table className="table datanew">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Customer</th>
                        <th>Amount </th>
                        <th className="no-sort">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0101</td>
                        <td>Walk-in Customer</td>
                        <td>$1500.00</td>
                        <td className="action-table-data">
                          <div className="edit-delete-action">
                            <Link className="me-2 p-2" to="#">
                              <i data-feather="eye" className="feather-eye" />
                            </Link>
                            <Link className="me-2 p-2" to="#">
                              <i data-feather="edit" className="feather-edit" />
                            </Link>
                            <Link
                              //   onClick={showConfirmationAlert}
                              className="p-2 confirm-text"
                              to="#"
                            >
                              <i
                                data-feather="trash-2"
                                className="feather-trash-2"
                              />
                            </Link>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0102</td>
                        <td>Walk-in Customer</td>
                        <td>$1500.00</td>
                        <td className="action-table-data">
                          <div className="edit-delete-action">
                            <Link className="me-2 p-2" to="#">
                              <i data-feather="eye" className="feather-eye" />
                            </Link>
                            <Link className="me-2 p-2" to="#">
                              <i data-feather="edit" className="feather-edit" />
                            </Link>
                            <Link
                              //   onClick={showConfirmationAlert}
                              className="p-2 confirm-text"
                              to="#"
                            >
                              <i
                                data-feather="trash-2"
                                className="feather-trash-2"
                              />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
            </div>
            {/* <div className="tab-pane fade" id="payment" role="tabpanel">
                <div className="table-top">
                  <div className="search-set">
                    <div className="search-input">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control form-control-sm formsearch"
                      />
                      <Link to className="btn btn-searchset">
                        <i data-feather="search" className="feather-search" />
                      </Link>
                    </div>
                  </div>
                  <div className="wordset">
                    <ul>
                      <li>
                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                          <Link>
                            <ImageWithBasePath
                              src="assets/img/icons/pdf.svg"
                              alt="img"
                            />
                          </Link>
                        </OverlayTrigger>
                      </li>
                      <li>
                        <OverlayTrigger
                          placement="top"
                          overlay={renderExcelTooltip}
                        >
                          <Link
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                          >
                            <ImageWithBasePath
                              src="assets/img/icons/excel.svg"
                              alt="img"
                            />
                          </Link>
                        </OverlayTrigger>
                      </li>
                      <li>
                        <OverlayTrigger
                          placement="top"
                          overlay={renderPrinterTooltip}
                        >
                          <Link
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                          >
                            <i
                              data-feather="printer"
                              className="feather-printer"
                            />
                          </Link>
                        </OverlayTrigger>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table datanew">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Customer</th>
                        <th>Amount </th>
                        <th className="no-sort">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0101</td>
                        <td>Walk-in Customer</td>
                        <td>$1500.00</td>
                        <td className="action-table-data">
                          <div className="edit-delete-action">
                            <Link className="me-2 p-2" to="#">
                              <i data-feather="eye" className="feather-eye" />
                            </Link>
                            <Link className="me-2 p-2" to="#">
                              <i data-feather="edit" className="feather-edit" />
                            </Link>
                            <Link
                              //   onClick={showConfirmationAlert}
                              className="p-2 confirm-text"
                              to="#"
                            >
                              <i
                                data-feather="trash-2"
                                className="feather-trash-2"
                              />
                            </Link>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0102</td>
                        <td>Walk-in Customer</td>
                        <td>$1500.00</td>
                        <td className="action-table-data">
                          <div className="edit-delete-action">
                            <Link className="me-2 p-2" to="#">
                              <i data-feather="eye" className="feather-eye" />
                            </Link>
                            <Link className="me-2 p-2" to="#">
                              <i data-feather="edit" className="feather-edit" />
                            </Link>
                            <Link
                              //   onClick={showConfirmationAlert}
                              className="p-2 confirm-text"
                              to="#"
                            >
                              <i
                                data-feather="trash-2"
                                className="feather-trash-2"
                              />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tab-pane fade" id="return" role="tabpanel">
                <div className="table-top">
                  <div className="search-set">
                    <div className="search-input">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control form-control-sm formsearch"
                      />
                      <Link to className="btn btn-searchset">
                        <i data-feather="search" className="feather-search" />
                      </Link>
                    </div>
                  </div>
                  <div className="wordset">
                    <ul>
                      <li>
                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                          <Link>
                            <ImageWithBasePath
                              src="assets/img/icons/pdf.svg"
                              alt="img"
                            />
                          </Link>
                        </OverlayTrigger>
                      </li>
                      <li>
                        <OverlayTrigger
                          placement="top"
                          overlay={renderExcelTooltip}
                        >
                          <Link
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                          >
                            <ImageWithBasePath
                              src="assets/img/icons/excel.svg"
                              alt="img"
                            />
                          </Link>
                        </OverlayTrigger>
                      </li>
                      <li>
                        <OverlayTrigger
                          placement="top"
                          overlay={renderPrinterTooltip}
                        >
                          <Link
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                          >
                            <i
                              data-feather="printer"
                              className="feather-printer"
                            />
                          </Link>
                        </OverlayTrigger>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table datanew">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Customer</th>
                        <th>Amount </th>
                        <th className="no-sort">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0101</td>
                        <td>Walk-in Customer</td>
                        <td>$1500.00</td>
                        <td className="action-table-data">
                          <div className="edit-delete-action">
                            <Link className="me-2 p-2" to="#">
                              <i data-feather="eye" className="feather-eye" />
                            </Link>
                            <Link className="me-2 p-2" to="#">
                              <i data-feather="edit" className="feather-edit" />
                            </Link>
                            <Link
                              //   onClick={showConfirmationAlert}
                              className="p-2 confirm-text"
                              to="#"
                            >
                              <i
                                data-feather="trash-2"
                                className="feather-trash-2"
                              />
                            </Link>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>19 Jan 2023</td>
                        <td>INV/SL0102</td>
                        <td>Walk-in Customer</td>
                        <td>$1500.00</td>
                        <td className="action-table-data">
                          <div className="edit-delete-action">
                            <Link className="me-2 p-2" to="#">
                              <i data-feather="eye" className="feather-eye" />
                            </Link>
                            <Link className="me-2 p-2" to="#">
                              <i data-feather="edit" className="feather-edit" />
                            </Link>
                            <Link
                              //   onClick={showConfirmationAlert}
                              className="p-2 confirm-text"
                              to="#"
                            >
                              <i
                                data-feather="trash-2"
                                className="feather-trash-2"
                              />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Transaction;
