import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addProduct, toggleModalSize } from "src/redux/store/pos";
import "./modal.css";
import { calculateDiscount, rupiahCurrency } from "src/helper/currency";
// import ImageWithBasePath from "src/core/img/imagewithbasebath";

const SizeItem = ({ item }) => {
  const pos = useSelector((state) => state.pos);
  const dispatch = useDispatch();
  const handleClick = (size, price, qty, discount) => {
    dispatch(
      addProduct({
        // id: pos.selectedItem.product_id,
        // assets: pos.selectedItem?.image[0],
        // product_title: pos.selectedItem.product_name,
        // cat_title: pos.selectedItem.product_code,
        // price: parseFloat(price),
        // quantity: parseInt(1),
        // size,
        itemProductId: pos.selectedItem.product_id,
        assets: pos.selectedItem?.image[0] ?? "",
        product_title: pos.selectedItem.product_alias,
        cat_title: pos.selectedItem.product_code,
        itemPrice: parseFloat(price),
        itemQty: parseInt(1),
        itemProductSize: size,
        itemDisc: discount,
        itemDiscAmount: calculateDiscount(parseFloat(price), discount)
          .discAmount,
        maxQty: qty,
      })
    );
    dispatch(toggleModalSize());
  };

  return (
    <div>
      <button
        className="border border-container border-1 d-flex justify-content-center align-items-center btn"
        role="button"
        onClick={() =>
          handleClick(item.size, item.price, item.qty, item.discount)
        }
        disabled={item.qty == 0}
      >
        <div className="d-flex flex-column align-content-center align-items-center">
          <h3>
            {item.qty == 0 ? <del>{item.size}</del> : item.size}
            {}
          </h3>
        </div>
      </button>

      <div className="d-flex flex-column align-items-center">
        <span
          className={`text-center fw-bold pt-2 ${
            item.qty == 0 ? `text-danger` : ``
          }`}
        >
          {item.qty == 0 ? `Sold out` : item.qty}
        </span>
        <span className="text-center">{rupiahCurrency.format(item.price)}</span>
      </div>
    </div>
  );
};

const SizeOption = () => {
  const pos = useSelector((state) => state.pos);
  // const [selectedSize, setSelectedSize] = useState(null);
  const dispatch = useDispatch();
  // const dispatch = useDispatch();
  // const [data, setData] = useState({
  //   name: "",
  //   email: "",
  // });
  const handleSubmit = (e) => {
    e.preventDefault();
    // alert("submitted");
    // dispatch(createUser(data));
    // setOpen(false);
  };

  // console.log("data ", data);
  // const sizes = ["S", "M", "L", "XL"];

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={pos.modalSize}
      onHide={() => dispatch(toggleModalSize())}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title>Choose Size</Modal.Title>
          <div className="d-flex align-items-center gap-2 mt-2">
            {/* <ImageWithBasePath
              src={pos.selectedItem.assets}
              alt="Products"
              width={25}
            /> */}
            {/* {Object.keys(pos.selectedItem).length !== 0 && ( */}
            {pos.selectedItem?.image?.length > 0 && (
              <img
                src={`${process.env.REACT_APP_ASSET}/${
                  pos?.selectedItem?.image[0] || ""
                }`}
                alt="Products"
                width={80}
                height={40}
              />
            )}
            <div className="d-flex flex-column">
              <span>{pos.selectedItem.product_name}</span>
              <span style={{ fontSize: "12px" }}>
                {pos.selectedItem.product_code}
              </span>
            </div>
          </div>
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center gap-2">
            {pos.selectedItem?.product_size?.map((item, key) => (
              <SizeItem key={key} item={item} />
            ))}
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
};

export default SizeOption;
