import React from "react";
import { Check } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectProduct } from "src/redux/store/pos";
// import { rupiahCurrency } from "src/helper/currency";

const Item = ({ item }) => {
  const staging = useSelector((state) => state.pos);
  const dispatch = useDispatch();

  const isSelected = () => {
    const check = staging.items.filter(
      (v) => v.itemProductId === item.product_id
    );
    if (check.length) {
      return "active";
    }
    return null;
  };

  const handleProduct = (item) => {
    // if (isSelected()) {
    //   dispatch(
    //     removeProduct({
    //       id: item.id,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     addProduct({
    //       id: item.id,
    //       assets: item.assets,
    //       product_title: item.product_title,
    //       cat_title: item.cat_title,
    //       price: parseFloat(item.price),
    //       quantity: parseInt(1),
    //     })
    //   );
    // }
    dispatch(selectProduct(item));
  };

  return (
    <div
      className="col-sm-2 col-md-6 col-lg-3 col-xl-3"
      role="button"
      onClick={() => handleProduct(item)}
    >
      <div className={`${`product-info default-cover card ${isSelected()}`}`}>
        <Link to="#" className="img-bg">
          <img
            src={`${process.env.REACT_APP_ASSET}/${
              item?.image?.length > 0 ? item?.image[0] : null
            }`}
            height={120}
            width={120}
            alt="products"
            className="img-product"
          />
          {/* <ImageWithBasePath src={item.assets} alt="Products" /> */}
          {isSelected() ? (
            <span>
              <Check className="feather-16" />
            </span>
          ) : null}
        </Link>
        <small className="cat-name">
          <Link to="#">{item.product_code}</Link>
        </small>
        <h6 className="product-name">
          <Link to="#">{item.product_name}</Link>
        </h6>
        {/* <div className="d-flex align-items-center justify-content-between price">
          <span>{item.stock}</span>
          <p>{rupiahCurrency.format(item.price)}</p>
        </div> */}
      </div>
    </div>
  );
};

export default Item;
