import React, { useState } from "react";
import ImageWithBasePath from "src/core/img/imagewithbasebath";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { all_routes } from "src/Router/all_routes";
import axios from "src/config/axios";
import { useAuth } from "src/hooks/useAuth";
// import axios from "axios";

const Login = () => {
  const navigate = useNavigate();
  const route = all_routes;
  const { setToken, user } = useAuth();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    setError("");
    e.preventDefault();
    try {
      // const res = await axios.post("https://dummyjson.com/auth/login", {
      const res = await axios.post(`${process.env.REACT_APP_API}/auth/login`, {
        // username: email,
        email,
        password,
        // expiresInMins: 60,
      });
      const { token } = res.data;
      setToken(token);
      return navigate("/dashboard");
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  return user ? (
    <Navigate to={"/dashboard"} />
  ) : (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper login-new">
          <div className="container">
            <div className="login-content user-login">
              <div className="login-logo">
                <ImageWithBasePath
                  src="assets/img/groot-logo.png"
                  alt="img"
                  height={90}
                />
                <Link to={route.dashboard} className="login-logo logo-white">
                  <img
                    src="assets/img/logo-white.png"
                    alt="logo-login"
                    height={80}
                  />
                </Link>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="login-userset">
                  <div className="login-userheading">
                    <h3>Sign In</h3>
                    <h4>
                      Access the {process.env.REACT_APP_NAME} panel using your
                      email and passcode.
                      {/* Access the DaunPOS panel using your email and passcode. */}
                    </h4>
                  </div>
                  {error && (
                    <div className="alert alert-outline-danger alert-dismissible fade show">
                      {error}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      >
                        <i className="fas fa-xmark" />
                      </button>
                    </div>
                  )}

                  <div className="form-login">
                    <label className="form-label">Email Address</label>
                    <div className="form-addons">
                      <input
                        type="text"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <ImageWithBasePath
                        src="assets/img/icons/mail.svg"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="form-login">
                    <label>Password</label>
                    <div className="pass-group">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        className="pass-input form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className={`fas toggle-password ${
                          isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={togglePasswordVisibility}
                      ></span>
                    </div>
                  </div>
                  <div className="form-login authentication-check">
                    <div className="row">
                      <div className="col-6">
                        <div className="custom-control custom-checkbox">
                          <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                            <input type="checkbox" />
                            <span className="checkmarks" />
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="col-6 text-end">
                        <Link
                          className="forgot-link"
                          to={route.forgotPasswordThree}
                        >
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="form-login">
                    {/* <Link className="btn btn-login" to={route.signin}>
                      Sign In
                    </Link> */}
                    <button className="btn btn-login" type="submit">
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
              <p>
                Copyright © {new Date().getFullYear()}{" "}
                {process.env.REACT_APP_NAME}. All rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
