// src/features/transactionSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "src/config/axios";

const initialState = {
  data: [],
  total: 0,
  params: {},
  loading: false,
  modalTransaction: false,
  loadingDetailTransaction: false,
  modalReprint: false,
  detailTransaction: {
    billingDate: null,
    billingCode: 0,
    billingDiscount: 0,
    billingSubtotal: 0,
    billingGrandTotal: 0,
    data: [],
  },
};

export const getTransaction = createAsyncThunk(
  "transaction",
  async (params) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/sales-invoice-list`,
        {
          warehouse_id: user.warehouse_id,
          ...params,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          // params: {
          //   warehouse_id: user.warehouse_id,
          // },
        }
      );
      const result = response.data;
      return {
        data: result.data.data,
        total: result.data.total,
        params,
      };
    } catch (error) {
      console.log(error);
    }
  }
);

export const getTransactionDetail = createAsyncThunk(
  "transaction-detail",
  async (params, { dispatch }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/sales-invoice-detail`,
        {
          billing_id: params.billing_id,
          ...params,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      // dispatch(toggleModalReprint());
      return {
        data: response.data.data,
        billing: response.data,
      };
    } catch (error) {
      console.log(error);
    }
  }
);

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    toggleModalTransaction: (state) => {
      if (!state.modalTransaction) {
        state.modalReprint = false;
      }
      state.modalTransaction = !state.modalTransaction;
    },
    toggleModalReprint: (state) => {
      //   if (state.)
      if (!state.modalReprint) {
        state.modalTransaction = false;
      }
      state.modalReprint = !state.modalReprint;
    },
    toggleModalPrints: (state) => {
      //   if (state.)
      state.modalReprint = !state.modalReprint;
    },
    closeModalTransaction: (state) => {
      state.modalTransaction = false;
    },
    closeModalReprint: (state) => {
      state.modalReprint = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTransaction.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.params = action.payload.params;
      state.loading = false;
    });
    builder.addCase(getTransaction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTransaction.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getTransactionDetail.fulfilled, (state, action) => {
      state.detailTransaction.data = action.payload.data;
      state.detailTransaction.billingCode = action.payload.billing.billing_code;
      state.detailTransaction.billingDate = action.payload.billing.billing_date;
      state.detailTransaction.billingDiscount =
        action.payload.billing.aggregated_data.discount;
      state.detailTransaction.billingSubtotal =
        action.payload.billing.aggregated_data.subtotal;
      state.detailTransaction.billingGrandTotal =
        action.payload.billing.aggregated_data.grandtotal;
      state.detailTransaction.billingPaid = action.payload.billing.billing_paid;
      state.detailTransaction.warehouseName =
        action.payload.billing.warehouse_name;
      state.detailTransaction.warehouseAddress =
        action.payload.billing.warehouse_address;
      state.detailTransaction.cashierName = action.payload.billing.cashier_name;
      state.detailTransaction.billingPaidType =
        action.payload.billing.billing_paid_type;
      state.loadingDetailTransaction = false;
    });
    builder.addCase(getTransactionDetail.pending, (state) => {
      state.loadingDetailTransaction = true;
    });
    builder.addCase(getTransactionDetail.rejected, (state) => {
      state.loadingDetailTransaction = false;
    });
  },
});

export const {
  toggleModalTransaction,
  toggleModalReprint,
  toggleModalPrints,
  closeModalTransaction,
  closeModalReprint,
} = transactionSlice.actions;
export default transactionSlice.reducer;
