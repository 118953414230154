// src/features/transferStockInSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "src/config/axios";

const initialState = {
  data: [],
  references: [],
  total: 0,
  params: {},
  loading: false,
  modalAdd: false,
  modalPrint: false,
  modalFollowUp: false,
  addItem: {
    createdDate: null,
    note: null,
    refNumber: {
      value: null,
      label: null,
    },
    store: {
      value: null,
      label: null,
    },
  },
  detail: {
    data: [],
  },
};

export const getTransferStockIn = createAsyncThunk(
  "stock/transfer/in",
  async (params) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await axios.get(
        `/v1/smb-pindah-gudang`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          params: {
            warehouse_id: user.warehouse_id,
            ...params,
          },
        }
        // `https://dummyjson.com/products?limit=${params.limit}&skip=${params.skip}&select=title,price,sku,weight,brand`
      );
      const result = response.data;
      const references = result.data.data.filter(
        (item) => item.stock_order_reference_id == user.warehouse_id
      );

      return {
        data: result.data.data,
        total: result.data.total,
        params,
        references,
      };
    } catch (error) {
      console.log(error);
    }
  }
);

export const createTransferStockIn = createAsyncThunk(
  "stock/transfer/in/create",
  async (data, { dispatch, getState }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await axios.post(`/v1/smb-pindah-gudang`, data, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const result = response.data;
      dispatch(getTransferStockIn(getState().transferStockIn.params));
      dispatch(toggleModalAdd());
      return result;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getTransferStockInDetail = createAsyncThunk(
  "stock/transfer/in/detail",
  async (params) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await axios.post(
        `/v1/smb-detail`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
        // `https://dummyjson.com/products?limit=${params.limit}&skip=${params.skip}&select=title,price,sku,weight,brand`
      );
      const result = response.data;
      return {
        data: result,
      };
      // return {
      //   data: result.data.data,
      //   total: result.data.total,
      //   params,
      // };
      // return result;
    } catch (error) {
      console.log(error);
    }
  }
);

const transferStockInSlice = createSlice({
  name: "transferStockIn",
  initialState,
  reducers: {
    toggleModalAdd: (state) => {
      if (state.modalAdd) {
        state.detail.data = [];
        state.addItem.refNumber.label = null;
        state.addItem.refNumber.value = null;
        state.addItem.store.label = null;
        state.addItem.store.value = null;
        state.addItem.createdDate = null;
        state.addItem.note = null;
      }
      state.modalAdd = !state.modalAdd;
    },
    toggleModalFollowUp: (state) => {
      state.modalFollowUp = !state.modalFollowUp;
    },
    toggleModalPrint: (state) => {
      state.modalPrint = !state.modalPrint;
    },
    setDate: (state, action) => {
      state.addItem.createdDate = action.payload;
    },
    setStore: (state, action) => {
      state.addItem.store = action.payload;
    },
    setNote: (state, action) => {
      state.addItem.note = action.payload;
    },
    setRefNumber: (state, action) => {
      state.addItem.refNumber.value = action.payload.value;
      state.addItem.refNumber.label = action.payload.label;
    },
    setItemNote: (state, action) => {
      const find = state.detail.data.find(
        (item) => item.id === action.payload.id
      );
      if (find) {
        find.note = action.payload.note;
      }
    },
    setItemQty: (state, action) => {
      const find = state.detail.data.find(
        (item) => item.id === action.payload.id
      );
      if (find) {
        find.qty = action.payload.qty;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTransferStockIn.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.params = action.payload.params;
      state.references = action.payload.references;
      state.loading = false;
    });
    builder.addCase(getTransferStockIn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTransferStockIn.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createTransferStockIn.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createTransferStockIn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTransferStockIn.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTransferStockInDetail.fulfilled, (state, action) => {
      state.detail.data = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getTransferStockInDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTransferStockInDetail.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const {
  toggleModalAdd,
  toggleModalFollowUp,
  toggleModalPrint,
  setRefNumber,
  setDate,
  setStore,
  setNote,
  setItemNote,
  setItemQty,
} = transferStockInSlice.actions;
export default transferStockInSlice.reducer;
