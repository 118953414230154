import axios from "axios";
export const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API,
});

customAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 400) {
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      window.location.href = "/signin";
    }
    return Promise.reject(error);
  }
);

export default customAxios;
