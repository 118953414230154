// src/features/transferStockOutSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "src/config/axios";

const initialState = {
  data: [],
  total: 0,
  params: {},
  loading: false,
  loadingCreate: false,
  successCreate: false,
  modalAdd: false,
  modalPrint: false,
  addItem: {
    createdDate: null,
    store: {
      label: null,
      value: null,
    },
    note: "",
    data: [],
  },
  detail: {
    data: [],
    warehouse_name: "",
    stock_order_code: "",
    stock_order_date: "",
  },
};

export const getTransferStockOut = createAsyncThunk(
  "stock/transfer/out",
  async (params) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await axios.get(
        `/v1/skb-pindah-gudang`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          params: {
            txtWarehouseId: user.warehouse_id,
            ...params,
          },
        }
        // `https://dummyjson.com/products?limit=${params.limit}&skip=${params.skip}&select=title,price,sku,weight,brand`
      );
      const result = response.data;
      return {
        data: result.data.data,
        total: result.data.total,
        params,
      };
      // return result;
    } catch (error) {
      console.log(error);
    }
  }
);

export const createTransferStockOut = createAsyncThunk(
  "stock/transfer/out/create",
  async (data, { dispatch, getState }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await axios.post(
        `/v1/skb-pindah-gudang`,
        { ...data, txtWarehouseId: user.warehouse_id },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const result = response.data;
      dispatch(getTransferStockOut(getState().transferStockOut.params));
      dispatch(toggleModalAdd());
      return result;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getTransferStockOutDetail = createAsyncThunk(
  "stock/transfer/out/detail",
  async (params, { dispatch }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await axios.post(
        `/v1/skb-detail`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
        // `https://dummyjson.com/products?limit=${params.limit}&skip=${params.skip}&select=title,price,sku,weight,brand`
      );
      dispatch(toggleModalPrint());
      const result = response.data;
      return {
        data: result,
        params,
      };
      // return {
      //   data: result.data.data,
      //   total: result.data.total,
      //   params,
      // };
      // return result;
    } catch (error) {
      console.log(error);
    }
  }
);

const transferStockOutSlice = createSlice({
  name: "transferStockOut",
  initialState,
  reducers: {
    toggleModalAdd: (state) => {
      if (!state.modalAdd) {
        state.addItem.createdDate = null;
        state.addItem.note = "";
        state.addItem.store = {
          value: null,
          label: null,
        };
        state.addItem.data = [];
      }
      state.modalAdd = !state.modalAdd;
    },
    toggleModalPrint: (state) => {
      state.modalPrint = !state.modalPrint;
      state.detail.data = [];
      state.detail.warehouse_name = "";
      state.detail.stock_order_code = "";
      state.detail.stock_order_date = "";
    },
    addProduct: (state, action) => {
      //   state.items.push(action.payload);
      //   state.totalAmount += action.payload.price * action.payload.quantity;
      const existingProduct = state.addItem.data.find(
        (item) =>
          item.id === action.payload.id && item.size === action.payload.size
      );
      if (existingProduct) {
        existingProduct.qty =
          parseInt(existingProduct.qty) + parseInt(action.payload.qty);
      } else {
        state.addItem.data.push(action.payload);
      }
    },
    removeProduct: (state, action) => {
      const index = state.addItem.data.findIndex(
        (item) =>
          item.id === action.payload.id && item.size === action.payload.size
      );
      if (index !== -1) {
        state.addItem.data.splice(index, 1);
      }
    },
    setDate: (state, action) => {
      state.addItem.createdDate = action.payload;
    },
    setStore: (state, action) => {
      state.addItem.store = action.payload;
    },
    setNote: (state, action) => {
      state.addItem.note = action.payload;
    },
    setItemNote: (state, action) => {
      const find = state.addItem.data.find(
        (item) => item.id === action.payload.id
      );
      if (find) {
        find.note = action.payload.note;
      }
    },
    setItemNoteDetail: (state, action) => {
      const find = state.detail.data.find(
        (item) => item.stock_order_detail_id === action.payload.id
      );
      if (find) {
        find.note = action.payload.note;
      }
    },
    setItemQtyDetail: (state, action) => {
      const find = state.detail.data.find(
        (item) => item.stock_order_detail_id === action.payload.id
      );
      if (find) {
        find.qty = action.payload.qty;
      }
    },
    clearDetail: (state) => {
      state.detail.data = [];
      state.detail.warehouse_name = "";
      state.detail.stock_order_code = "";
      state.detail.stock_order_date = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTransferStockOut.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.params = action.payload.params;
      state.loading = false;
    });
    builder.addCase(getTransferStockOut.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTransferStockOut.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createTransferStockOut.fulfilled, (state) => {
      state.loadingCreate = false;
      state.successCreate = true;
      state.detail.data = [];
      state.detail.warehouse_name = "";
      state.detail.stock_order_code = "";
      state.detail.stock_order_date = "";
    });
    builder.addCase(createTransferStockOut.pending, (state) => {
      state.loadingCreate = true;
      state.successCreate = false;
    });
    builder.addCase(createTransferStockOut.rejected, (state) => {
      state.loadingCreate = false;
      state.successCreate = false;
    });
    builder.addCase(getTransferStockOutDetail.fulfilled, (state, action) => {
      state.detail.data = action.payload.data;
      state.detail.warehouse_name = action.payload.params.warehouse_name;
      state.detail.stock_order_code = action.payload.params.stock_order_code;
      state.detail.stock_order_date = action.payload.params.stock_order_date;
      state.loading = false;
    });
    builder.addCase(getTransferStockOutDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTransferStockOutDetail.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  toggleModalAdd,
  toggleModalPrint,
  addProduct,
  removeProduct,
  setDate,
  setNote,
  setStore,
  setItemNote,
  setItemNoteDetail,
  setItemQtyDetail,
  clearDetail,
} = transferStockOutSlice.actions;
export default transferStockOutSlice.reducer;
