import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import Header from "../InitialPage/Sidebar/Header";
// import Sidebar from "../InitialPage/Sidebar/Sidebar";
import { posRoutes, privateRoutes, publicRoutes } from "./router.link";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
// import ThemeSettings from "../InitialPage/themeSettings";
import PrivateRoute from "src/components/PrivateRoute";
import Header from "src/InitialPage/Sidebar/Header";
import Sidebar from "src/InitialPage/Sidebar/Sidebar";

const AllRoutes = () => {
  const data = useSelector((state) => state.toggle_header);
 
  const PrivatePages = () => ( 
    <PrivateRoute>
      <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
        {/* <Loader /> */}
        <Header />
        <Sidebar />
        <Outlet />
        {/* <ThemeSettings /> */}
      </div>
    </PrivateRoute>
  );

  const PublicPages = () => (
    <div className={data ? "header-collapse" : ""}>
      <Outlet />
      {/* <Header /> */}
      {/* <Loader /> */}
      {/* <ThemeSettings /> */}
    </div>
  );

  const Pospages = () => (
    <PrivateRoute>
    <Header />
    <Outlet />
    {/* <Loader /> */}
    {/* <ThemeSettings /> */}
    </PrivateRoute>
);

  // const Authpages = () => (
  //   <div className={data ? "header-collapse" : ""}>
  //     <Outlet />
  //     {/* <Loader /> */}
  //     {/* <ThemeSettings /> */}
  //   </div>
  // );

  return (
    <div>
      <Routes>
        {/* <Route path={"/"} element={<PrivatePages />}>
          {publicRoutes.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>

        <Route path={"/"} element={<Authpages />}>
          {pagesRoute.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route> */}
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/pos" element={<Pospages />}>
          {posRoutes.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>

        <Route path={"/"}  element={<PublicPages />}>
          {publicRoutes.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>

        <Route path={"/"} element={<PrivatePages />}>
          {privateRoutes.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>
      </Routes>
    </div>
  );
};
export default AllRoutes;
