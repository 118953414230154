import React from "react";
import { Modal } from "react-bootstrap";
import PrintableComponent from "./PrintableComponent";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import {
  closeModalReprint,
  closeModalTransaction,
  toggleModalReprint,
} from "src/redux/store/transaction";
import { printReceipt } from "src/helper/print";
import { closeModalCompletedPayment, finishOrder } from "src/redux/store/pos";

const ReprintReceipt = () => {
  const transaction = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handlePrint = () => {
    printReceipt("reprint-receipt");
    dispatch(closeModalCompletedPayment());
    dispatch(closeModalTransaction());
    dispatch(closeModalReprint());
    dispatch(finishOrder());
  };

  return (
    <Modal
      id="print-receipt"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transaction.modalReprint}
      onHide={() => dispatch(toggleModalReprint())}
    >
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <PrintableComponent pos={transaction} />

          <div className="d-flex justify-content-center my-3">
            <div
              to="#"
              className="btn btn-primary non-printable"
              role="button"
              onClick={handlePrint}
            >
              Print Receipt
            </div>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
};

export default ReprintReceipt;
