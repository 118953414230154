// src/hooks/useAuth.js

import { useState, useCallback, useEffect } from "react";
// import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "src/config/axios";

const TOKEN_KEY = "token";

export const useAuth = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const setToken = useCallback(async (token) => {
    localStorage.setItem(TOKEN_KEY, token);
    const res = await me(token);
    localStorage.setItem("userData", JSON.stringify(res));
  }, []);

  const getToken = useCallback(() => {
    return localStorage.getItem(TOKEN_KEY);
  }, []);

  const removeToken = useCallback(() => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem("userData");
    setUser(null);
    navigate("/signin");
  }, [navigate]);

  const getUserFromToken = useCallback(() => {
    const token = getToken();
    if (!token) return null;
    // const data = me(token)
    // return data
    // return jwtDecode(token);
    // return jwtDecode(token);
    return token;
  }, [getToken]);

  const me = async (token) => {
    const res = await axios.post(
      `${process.env.REACT_APP_API}/auth/me`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setUser({
      ...res.data.data,
      token,
    });
    const r = {
      ...res.data.data,
      token,
    };
    return r;
  };

  useEffect(() => {
    const token = getUserFromToken();
    if (token) {
      // setUser(token);
      me(token);
    }
  }, [getUserFromToken]);

  return {
    user,
    setToken,
    getToken,
    removeToken,
    getUserFromToken,
  };
};
