// src/components/PrivateRoute.js

import { useAuth } from "src/hooks/useAuth";
import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const { getToken } = useAuth();
  return getToken() ? children : <Navigate to="/signin" />;
};

export default PrivateRoute;
