// src/features/roleSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "src/config/axios";

const initialState = {
  data: [],
  total: 0,
  loading: false,
};

export const getRole = createAsyncThunk("role", async (params) => {
  try {
    const response = await axios.get(
      `https://dummyjson.com/users?limit=${params.limit}&skip=${params.skip}&select=role`
    );
    const result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
});

export const createRole = createAsyncThunk("role/create", async (data) => {
  try {
    const response = await axios.post(`https://dummyjson.com/products/add`, {
      data,
    });
    const result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
});

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRole.fulfilled, (state, action) => {
      state.data = action.payload.users;
      state.total = action.payload.total;
      state.loading = false;
    });
    builder.addCase(getRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRole.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createRole.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createRole.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default roleSlice.reducer;
