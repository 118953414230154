export const dollarCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const receiptCurrency = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  currencyDisplay: "code",
});

export const rupiahCurrency = new Intl.NumberFormat("id-ID", {
  style: "decimal",
});

export const calculateDiscount = (price, disc) => {
  const discAmount = Math.round((price * disc) / 100);
  const afterDisc = Math.round(price - discAmount);
  return {
    discAmount,
    afterDisc,
  };
};

export const calculateAllDiscount = (products) => {
  let discount = 0;
  for (let index = 0; index < products.length; index++) {
    const product = products[index];
    const discAmount = (product.itemPrice * product.itemDisc) / 100;

    discount += discAmount;
  }
  return discount;
};

export const calculateVAT = (subtotal) => {
  let vat = 0;
  const temp = Math.round((subtotal * 100) / 111);
  vat = subtotal - temp;
  return vat;
};
