import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { rupiahCurrency } from "src/helper/currency";

const GrandTotal = () => {
  const staging = useSelector((state) => state.pos);
  return (
    <div className="d-grid btn-block">
      <Link className="btn btn-secondary" to="#">
        Grand Total :{" "}
        {rupiahCurrency.format(staging.totalAmount - staging.totalDiscount)}
      </Link>
    </div>
  );
};

export default GrandTotal;
