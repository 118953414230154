import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleModalPrint } from "src/redux/store/transferStockOut";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Printer } from "react-feather";
import { printLetter } from "src/helper/print";
import moment from "moment";

const PrintModal = () => {
  const transferStockOut = useSelector((state) => state.transferStockOut);
  const dispatch = useDispatch();

  const handlePrint = () => {
    printLetter("skb");
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transferStockOut.modalPrint}
      onHide={() => dispatch(toggleModalPrint())}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title>Preview Print</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body id="skb" className="p-5">
        <div className="text-center pb-3">
          <h4 className="text-uppercase">Surat Keluar Barang</h4>
        </div>

        <div className="row my-3">
          <div className="col-6">
            <div className="d-flex flex-column gap-2">
              <table>
                <tr>
                  <td>No. SKB</td>
                  <td>:</td>
                  <td className="fw-bolder">
                    {transferStockOut.detail.stock_order_code}
                  </td>
                </tr>
                <tr>
                  <td>Tgl. Picking</td>
                  <td>:</td>
                  <td className="fw-bolder">
                    {transferStockOut.detail.stock_order_date}
                  </td>
                </tr>
                <tr>
                  <td>Untuk</td>
                  <td>:</td>
                  <td className="fw-bolder">
                    {transferStockOut.detail.warehouse_name}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="">
          <table className="table-print mb-0">
            <thead>
              <tr>
                <th className="text-uppercase fw-bolder text-center">No</th>
                <th className="text-uppercase fw-bolder text-center">Produk</th>
                <th className="text-uppercase fw-bolder text-center">
                  Produk Alias
                </th>
                <th className="text-uppercase fw-bolder text-center">Qty</th>
                <th className="text-uppercase fw-bolder text-center">Size</th>
                <th className="text-uppercase fw-bolder text-center">Note</th>
              </tr>
            </thead>
            <tbody>
              {transferStockOut.detail.data.map((item, key) => (
                <tr key={key}>
                  <td className="padding-fix">{key + 1}</td>
                  <td className="padding-fix">{item.product_name}</td>
                  <td className="padding-fix">{item.product_alias}</td>
                  <td className="text-center">{item.qty}</td>
                  <td className="text-center">{item.product_size}</td>
                  <td>{item.stock_order_detail_note}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <table className="w-100 mt-3">
            <tr>
              <td colSpan={3} className="fw-bolder text-end">
                TOTAL
              </td>
              <td colSpan={3} className="fw-bolder text-center">
                100
              </td>
            </tr>
          </table>
        </div>

        <div className="d-flex mt-3">
          <i>Tgl. Cetak : {moment().format("YYYY-MM-DD")}</i>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex align-items-center justify-content-end gap-2">
          <button
            className="btn btn-secondary"
            onClick={() => dispatch(toggleModalPrint())}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary d-flex align-items-center justify-content-center"
            onClick={handlePrint}
          >
            <Printer size={18} />
            <span className="ms-2">Print</span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintModal;
