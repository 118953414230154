import React from "react";
import { calculateVAT, receiptCurrency } from "src/helper/currency";
import "./style.css";
import Footer from "../Footer";
import Header from "../Header";

const PrintableComponent = ({ pos }) => {
  return (
    <div className="printable-component p-5" id="reprint-receipt">
      <Header grandTotal={pos.detailTransaction.billingGrandTotal} />
      <table className="w-100 table-fit">
        <thead>
          <tr>
            <th style={{ width: "80%" }}>Items</th>
            <th className="text-end">Price</th>
            {/* <th>Qty</th>
            <th className="text-end">Total</th> */}
          </tr>
        </thead>
        <tbody className="content-row">
          {pos.detailTransaction.data.map((item, key) => (
            <tr
              key={key}
              className={`${
                key != pos.detailTransaction.data.length - 1
                  ? `border-bottom-dashed`
                  : ``
              }`}
            >
              <td>
                <div className="d-flex flex-column">
                  <span className="fw-bold">
                    {item.product_alias} [{item.product_size}]
                  </span>
                  <span>{item.qty}</span>
                  <span style={{ fontSize: "10px" }}>
                    {item.product_size_code}
                  </span>
                </div>
              </td>
              <td className="text-end" style={{ verticalAlign: "top" }}>
                <div className="d-flex flex-column">
                  <div className="fw-bold">
                    {receiptCurrency.format(item.grandtotal).replace(".", ",")}
                  </div>
                  {item.discount > 0 ? (
                    <del>
                      {receiptCurrency.format(item.subtotal).replace(".", ",")}
                    </del>
                  ) : null}
                </div>
              </td>
              {/* <td>{item.qty}</td>
              <td className="text-end">
                {rupiahCurrency.format(item.subtotal)}
              </td> */}
            </tr>
          ))}
          <tr>
            <td colSpan={4}>
              <table className="w-100 table-fit">
                <tbody>
                  <tr>
                    <td className="fw-bold">SUBTOTAL</td>
                    <td className="text-end fw-bold">
                      {receiptCurrency
                        .format(
                          pos.detailTransaction.billingSubtotal -
                            pos.detailTransaction.billingDiscount
                        )
                        .replace(".", ",")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex flex-column">
                        <div className="fw-bold">VAT(11%)</div>
                        <div style={{ fontSize: "10px" }}>
                          Included in product price
                        </div>
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      {receiptCurrency
                        .format(
                          calculateVAT(
                            pos.detailTransaction.billingSubtotal -
                              pos.detailTransaction.billingDiscount
                          )
                        )
                        .replace(".", ",")}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-secondary fw-bolder">TOTAL</td>
                    <td className="text-secondary fw-bolder text-end">
                      {receiptCurrency
                        .format(pos.detailTransaction.billingGrandTotal)
                        .replace(".", ",")}
                    </td>
                  </tr>
                  {/* {pos.detailTransaction.billingPaid > 0 && (
                    <tr
                      style={{
                        borderTop: "1px dashed #444",
                      }}
                    >
                      <td className="text-end">Cash :</td>
                      <td className="text-end">
                        {rupiahCurrency.format(
                          pos.detailTransaction.billingPaid
                        )}
                      </td>
                    </tr>
                  )}
                  {pos.detailTransaction.billingPaid > 0 && (
                    <tr>
                      <td className="text-end">Change :</td>
                      <td className="text-end">
                        {rupiahCurrency.format(
                          pos.detailTransaction.billingPaid -
                            pos.detailTransaction.billingGrandTotal
                        )}
                      </td>
                    </tr>
                  )} */}
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <table className="w-100 table-fit">
                <tbody>
                  <tr>
                    <td>{pos.detailTransaction.billingPaidType}</td>
                    <td className="text-end fw-bold">
                      {receiptCurrency
                        .format(pos.detailTransaction.billingGrandTotal)
                        .replace(".", ",")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <Footer
        billingCode={pos.detailTransaction.billingCode}
        transDate={pos.detailTransaction.billingDate}
        cashierName={pos.detailTransaction.cashierName}
      />
    </div>
  );
};

export default PrintableComponent;
