import React from "react";
import Item from "./Item";
import { useDispatch, useSelector } from "react-redux";
import { removeAllProducts } from "src/redux/store/pos";

const Staging = () => {
  const staging = useSelector((state) => state.pos);
  const dispatch = useDispatch();

  const handleEmptyProdcuts = () => {
    dispatch(removeAllProducts());
  };
  return (
    <div className="product-added block-section">
      <div className="head-text d-flex align-items-center justify-content-between">
        <h6 className="d-flex align-items-center mb-0">
          Product Added<span className="count">{staging.items.length}</span>
        </h6>
        {staging.items.length > 0 && (
          <div
            role="button"
            className="d-flex align-items-center text-danger"
            onClick={handleEmptyProdcuts}
          >
            <span className="me-1">
              <i data-feather="x" className="feather-16" />
            </span>
            Clear all
          </div>
        )}
      </div>
      <div className="product-wrap">
        {staging.items.length > 0 ? (
          staging.items.map((item, key) => <Item item={item} key={key} />)
        ) : (
          <div className="alert alert-warning" role="alert">
            No product added
          </div>
        )}
      </div>
    </div>
  );
};

export default Staging;
