import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addProduct,
  createTransferStockOut,
  removeProduct,
  setDate,
  setItemNote,
  setNote,
  setStore,
  toggleModalAdd,
} from "src/redux/store/transferStockOut";
// import SelectAsync from "react-select/async";
import Select from "react-select";
import Table from "src/core/pagination/datatable";
import { Calendar, PlusCircle, Trash2 } from "react-feather";
import { Divider } from "antd";
// import "./style.css";
// import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getProduct } from "src/redux/store/product";
import moment from "moment";

const AddModal = () => {
  const transferStockOut = useSelector((state) => state.transferStockOut);
  const warehouse = useSelector((state) => state.warehouse);
  const product = useSelector((state) => state.product);
  const [size, setSize] = useState([]);
  const [item, setItem] = useState({
    id: null,
    title: "",
    qty: 0,
    size: null,
    note: "",
    maxQty: 0,
  });
  const dispatch = useDispatch();
  //   const loadProduct = async (inputValue, callback) => {
  //     console.log("-", inputValue);
  //     try {
  //       const response = await axios.get(
  //         `https://dummyjson.com/products/search`,
  //         {
  //           params: {
  //             q: inputValue,
  //           },
  //         }
  //       );
  //       console.log("response ", response);
  //       const options = response.data.products.map((option) => ({
  //         label: `${option.title}`,
  //         value: option.id,
  //       }));
  //       callback(options);
  //     } catch (error) {
  //       console.error("Error fetching options:", error);
  //     }
  //   };

  const handleDateChange = (date) => {
    dispatch(setDate(date));
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (value, item, index) => index + 1,
    },
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Product",
      dataIndex: "title",
    },
    {
      title: "Qty",
      dataIndex: "qty",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Note",
      dataIndex: "note",
      render: (value, item, index) => {
        return (
          <div>
            <input
              type="text"
              className="form-control"
              onChange={(e) =>
                dispatch(setItemNote({ id: item.id, note: e.target.value }))
              }
            />
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (value, item, index) => {
        return (
          <div className="action-table-data">
            <div className="edit-delete-action">
              <a
                className="confirm-text"
                role="button"
                onClick={() =>
                  dispatch(
                    removeProduct({
                      id: item.id,
                      size: item.size,
                    })
                  )
                }
              >
                <Trash2 className="feather-trash-2" />
              </a>
            </div>
          </div>
        );
      },
    },
  ];

  //   const handleChange = (e) => {
  //     console.log(e);
  //     setItem({
  //       ...item,
  //       title: e.label,
  //       id: e.value,
  //     });
  //   };

  useEffect(() => {
    if (item.id) {
      const temp = product.data.find((item2) => item2.product_id == item.id);
      if (temp) {
        const tempSize = temp.product_size.map((item) => ({
          value: item.size,
          label: `${item.size} | QTY (${item.qty})`,
          isDisabled: item.qty === 0,
          maxQty: item.qty,
        }));
        setSize(tempSize);
      }
    }
  }, [item]);

  const handleAddProduct = (e) => {
    e.preventDefault();
    if (!item.id || item.qty == 0 || !item.size) {
      alert("Please fill all the field");
      return false;
    }
    dispatch(addProduct(item));
    setItem({
      id: null,
      title: "",
      qty: 0,
      note: "",
      size: null,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !transferStockOut.addItem.createdDate ||
      !transferStockOut.addItem.store.value ||
      transferStockOut.addItem.data.length == 0
    ) {
      alert("Please filled all the form field");
      return false;
    }

    const itemProduct = transferStockOut.addItem.data.map((item) => ({
      itemProductId: item.id,
      itemProductSize: item.size,
      itemQty: item.qty,
      itemNote: item.note,
    }));

    const data = {
      txtStockDate: moment(transferStockOut.addItem.createdDate).format(
        "YYYY-MM-DD"
      ),
      // txtStockDate: transferStockOut.addItem.createdAt,
      txtTo: transferStockOut.addItem.store.value,
      txtStockNote: transferStockOut.addItem.note,
      itemProduct,
    };

    dispatch(createTransferStockOut(data));
  };

  useEffect(() => {
    dispatch(getProduct());
  }, []);

  useEffect(() => {
    if (!transferStockOut.modalAdd) {
      setItem({
        id: null,
        title: "",
        qty: 0,
        size: null,
        maxQty: 0,
      });
    }
  }, [transferStockOut]);

  // const warehouse = [
  //   {
  //     label: "Warehouse",
  //     value: 1,
  //   },
  // ];

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transferStockOut.modalAdd}
      onHide={() => dispatch(toggleModalAdd())}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title>Create New Surat Keluar Barang</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg">
            <div className="input-blocks">
              <label>Created Date</label>
              <div className="input-blocks">
                <Calendar className="info-img" />
                <div className="input-groupicon">
                  <DatePicker
                    selected={transferStockOut.addItem.createdDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                    className="datetimepicker"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="">
              <label>To Warehouse</label>
              <Select
                className="mt-2"
                classNamePrefix="react-select"
                options={warehouse.data.map((item) => {
                  return {
                    value: item.warehouse_id,
                    label: `${item.warehouse_code} | ${item.warehouse_name}`,
                  };
                })}
                value={transferStockOut.addItem.store}
                onChange={(e) => dispatch(setStore(e))}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-blocks">
              <label>Note</label>
              <input
                type="text"
                className="form-control"
                value={transferStockOut.addItem.note}
                onChange={(e) => dispatch(setNote(e.target.value))}
              />
            </div>
          </div>
        </div>

        <Divider>Manage Product</Divider>
        <form onSubmit={handleAddProduct}>
          <div className="row align-items-end">
            <div className="col-lg-4">
              <div className="mb-3">
                <label>Product</label>
                {/* <SelectAsync
                  classNamePrefix="react-select"
                  cacheOptions
                  loadOptions={loadProduct}
                  onChange={handleChange}
                  placeholder="Type product"
                  value={{
                    label: item.title,
                    value: item.id,
                  }}
                /> */}
                <Select
                  classNamePrefix="react-select"
                  className="mt-2"
                  options={product.data.map((item) => ({
                    label: item.product_alias,
                    value: item.product_id,
                  }))}
                  value={{
                    label: item.title,
                    value: item.id,
                  }}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      id: e.value,
                      title: e.label,
                      size: null,
                    })
                  }
                />
              </div>
            </div>
            <div className="col">
              <div className="input-blocks">
                <label>Size</label>
                <Select
                  classNamePrefix="react-select"
                  options={size}
                  value={{
                    label: item.size,
                    value: item.size,
                  }}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      size: e.value,
                      maxQty: e.maxQty,
                    })
                  }
                />
              </div>
            </div>
            <div className="col">
              <div className="input-blocks">
                <label>Qty {item.size && `| Max (${item.maxQty})`}</label>
                <input
                  type="number"
                  className="form-control"
                  min={1}
                  value={item.qty}
                  onFocus={() => {
                    if (item.qty == 0) {
                      setItem({
                        ...item,
                        qty: "",
                      });
                    }
                  }}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      qty: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col mb-3">
              <button
                type="submit"
                className="btn btn-submit d-flex align-items-center gap-1"
              >
                <PlusCircle size={14} />
                <span>Add Product</span>
              </button>
            </div>
          </div>
        </form>

        <Table
          tableClassName={"tight-table"}
          columns={columns}
          dataSource={transferStockOut.addItem.data}
          //   loading={transferStockOut.loading}
          //   total={transferStockOut.total}
          pagination={false}
          // handleChange={handleChange}
        />
        <div className="modal-footer-btn">
          <button
            type="button"
            className="btn btn-cancel me-2"
            onClick={() => dispatch(toggleModalAdd())}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-submit"
            disabled={transferStockOut.loadingCreate}
            onClick={handleSubmit}
          >
            {transferStockOut.loadingCreate ? `Processing...` : `Create`}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddModal;
