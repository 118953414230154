import React from "react";
import { calculateVAT, receiptCurrency } from "src/helper/currency";
import Header from "./Header";
import Footer from "./Footer";

const PrintableComponent = ({ pos }) => {
  return (
    <div className="printable-component p-5" id="print-receipts">
      <Header grandTotal={pos.totalAmount - pos.totalDiscount} />
      <table className="w-100 table-fit">
        <thead>
          <tr>
            <th style={{ width: "80%" }}>Items</th>
            <th className="text-end">Price</th>
          </tr>
        </thead>
        <tbody className="content-row">
          {pos.items.map((item, key) => (
            <tr
              key={key}
              className={`${
                key != pos.items.length - 1 ? `border-bottom-dashed` : ``
              }`}
            >
              <td>
                <div className="d-flex flex-column">
                  <span className="fw-bold">
                    {item.product_title} [{item.itemProductSize}]
                  </span>
                  <span>{item.itemQty}</span>
                  <span style={{ fontSize: "10px" }}>{item.cat_title}</span>
                </div>
              </td>
              {/* <td className="text-end">
                {rupiahCurrency.format(item.itemPrice)}
              </td>
              <td>{item.itemQty}</td>
              <td className="text-end">
                {rupiahCurrency.format(item.itemPrice * item.itemQty)}
              </td> */}
              <td className="text-end" style={{ verticalAlign: "top" }}>
                <div className="d-flex flex-column">
                  <div className="fw-bold">
                    {receiptCurrency
                      .format(
                        item.itemPrice * item.itemQty - item.itemDiscAmount
                      )
                      .replace(".", ",")}
                  </div>
                  {item.itemDiscAmount > 0 ? (
                    <del>
                      {receiptCurrency
                        .format(item.itemPrice * item.itemQty)
                        .replace(".", ",")}
                    </del>
                  ) : null}
                </div>
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={4}>
              {/* <table className="table-borderless w-100 table-fit">
                <tbody>
                  <tr>
                    <td>Sub Total :</td>
                    <td className="text-end">
                      {rupiahCurrency.format(pos.totalAmount)}
                    </td>
                  </tr>
                  <tr>
                    <td>Discount :</td>
                    <td className="text-end">0</td>
                  </tr>
                  <tr>
                    <td>Tax (5%) :</td>
                    <td className="text-end">0</td>
                  </tr>
                  <tr>
                    <td className="text-secondary fw-bolder">
                      Total Payable :
                    </td>
                    <td className="text-end text-secondary fw-bolder">
                      {rupiahCurrency.format(pos.totalAmount)}
                    </td>
                  </tr>
                </tbody>
              </table> */}
              <table className="w-100 table-fit">
                <tbody>
                  <tr>
                    <td className="fw-bold">SUBTOTAL</td>
                    <td className="text-end fw-bold">
                      {receiptCurrency
                        .format(pos.totalAmount - pos.totalDiscount)
                        .replace(".", ",")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex flex-column">
                        <div className="fw-bold">VAT(11%)</div>
                        <div>Included in product price</div>
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      {receiptCurrency
                        .format(
                          calculateVAT(pos.totalAmount - pos.totalDiscount)
                        )
                        .replace(".", ",")}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-secondary fw-bolder">TOTAL</td>
                    <td className="text-secondary fw-bolder text-end">
                      {receiptCurrency
                        .format(pos.totalAmount - pos.totalDiscount)
                        .replace(".", ",")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <table className="w-100 table-fit">
                <tbody>
                  <tr>
                    <td>{pos.paymentType}</td>
                    <td className="text-end fw-bold">
                      {receiptCurrency
                        .format(pos.totalAmount - pos.totalDiscount)
                        .replace(".", ",")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <Footer billingCode={"[billing_code]"} transDate={"[billing_date]"} />
    </div>
  );
};

export default PrintableComponent;
