import React from "react";
import Item from "./Item";
import { useSelector } from "react-redux";
import "./style.css";

const ProductPOS = () => {
  const product = useSelector((state) => state.product);
  return (
    <div className="tabs_container">
      <div className="tab_content active" data-tab="all">
        <div className="row">
          {product.loading
            ? [...Array(16)].map((item, key) => (
                <div
                  className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2"
                  key={key}
                >
                  <div className="product-info default-cover card loading-item"></div>
                </div>
              ))
            : product.data.map((item, key) => <Item key={key} item={item} />)}
          {/* <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-02.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Computer</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">MacBook Pro</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>140 Pcs</span>
                <p>$1000</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-03.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Watches</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Rolex Tribute V3</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>220 Pcs</span>
                <p>$6800</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-04.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Shoes</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Red Nike Angelo</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>78 Pcs</span>
                <p>$7800</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-05.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Headphones</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Airpod 2</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>47 Pcs</span>
                <p>$5478</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-06.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Shoes</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Blue White OGR</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>54 Pcs</span>
                <p>$987</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-07.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Laptop</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">IdeaPad Slim 5 Gen 7</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>74 Pcs</span>
                <p>$1454</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-08.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Headphones</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">SWAGME</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>14 Pcs</span>
                <p>$6587</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-09.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Watches</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Timex Black SIlver</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>24 Pcs</span>
                <p>$1457</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-10.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Computer</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Tablet 1.02 inch</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>14 Pcs</span>
                <p>$4744</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-11.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Watches</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Fossil Pair Of 3 in 1 </Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>40 Pcs</span>
                <p>$789</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-18.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Shoes</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Green Nike Fe</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>78 Pcs</span>
                <p>$7847</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="tab_content" data-tab="headphones">
        <div className="row">
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-05.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Headphones</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Airpod 2</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>47 Pcs</span>
                <p>$5478</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-08.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Headphones</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">SWAGME</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>14 Pcs</span>
                <p>$6587</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tab_content" data-tab="shoes">
        <div className="row">
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-04.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Shoes</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Red Nike Angelo</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>78 Pcs</span>
                <p>$7800</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-06.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Shoes</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Blue White OGR</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>54 Pcs</span>
                <p>$987</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-18.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Shoes</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Green Nike Fe</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>78 Pcs</span>
                <p>$7847</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tab_content" data-tab="mobiles">
        <div className="row">
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-01.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Mobiles</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">IPhone 14 64GB</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>30 Pcs</span>
                <p>$15800</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-14.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Mobiles</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Iphone 11</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>14 Pcs</span>
                <p>$3654</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tab_content" data-tab="watches">
        <div className="row">
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-03.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Watches</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Rolex Tribute V3</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>220 Pcs</span>
                <p>$6800</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-09.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Watches</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Timex Black SIlver</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>24 Pcs</span>
                <p>$1457</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-11.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Watches</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Fossil Pair Of 3 in 1 </Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>40 Pcs</span>
                <p>$789</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tab_content" data-tab="laptops">
        <div className="row">
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-02.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Computer</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">MacBook Pro</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>140 Pcs</span>
                <p>$1000</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-07.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Laptop</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">IdeaPad Slim 5 Gen 7</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>74 Pcs</span>
                <p>$1454</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-10.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Computer</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Tablet 1.02 inch</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>14 Pcs</span>
                <p>$4744</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-13.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Laptop</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Yoga Book 9i</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>65 Pcs</span>
                <p>$4784</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-14.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Laptop</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">IdeaPad Slim 3i</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>47 Pcs</span>
                <p>$1245</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tab_content" data-tab="allcategory">
        <div className="row">
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-01.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Mobiles</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">IPhone 14 64GB</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>30 Pcs</span>
                <p>$15800</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-02.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Computer</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">MacBook Pro</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>140 Pcs</span>
                <p>$1000</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-03.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Watches</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Rolex Tribute V3</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>220 Pcs</span>
                <p>$6800</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-04.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Shoes</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Red Nike Angelo</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>78 Pcs</span>
                <p>$7800</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-05.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Headphones</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Airpod 2</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>47 Pcs</span>
                <p>$5478</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-06.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Shoes</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Blue White OGR</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>54 Pcs</span>
                <p>$987</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-07.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Laptop</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">IdeaPad Slim 5 Gen 7</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>74 Pcs</span>
                <p>$1454</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-08.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Headphones</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">SWAGME</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>14 Pcs</span>
                <p>$6587</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-09.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Watches</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Timex Black SIlver</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>24 Pcs</span>
                <p>$1457</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-10.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Computer</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Tablet 1.02 inch</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>14 Pcs</span>
                <p>$4744</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-11.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Watches</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Fossil Pair Of 3 in 1 </Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>40 Pcs</span>
                <p>$789</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-18.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Shoes</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Green Nike Fe</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>78 Pcs</span>
                <p>$7847</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tab_content" data-tab="headphone">
        <div className="row">
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-05.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Headphones</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Airpod 2</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>47 Pcs</span>
                <p>$5478</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-08.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Headphones</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">SWAGME</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>14 Pcs</span>
                <p>$6587</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tab_content" data-tab="shoe">
        <div className="row">
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-04.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Shoes</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Red Nike Angelo</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>78 Pcs</span>
                <p>$7800</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-06.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Shoes</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Blue White OGR</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>54 Pcs</span>
                <p>$987</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-18.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Shoes</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Green Nike Fe</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>78 Pcs</span>
                <p>$7847</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tab_content" data-tab="mobile">
        <div className="row">
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-01.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Mobiles</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">IPhone 14 64GB</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>30 Pcs</span>
                <p>$15800</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-14.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Mobiles</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Iphone 11</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>14 Pcs</span>
                <p>$3654</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tab_content" data-tab="watche">
        <div className="row">
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-03.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Watches</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Rolex Tribute V3</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>220 Pcs</span>
                <p>$6800</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-09.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Watches</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Timex Black SIlver</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>24 Pcs</span>
                <p>$1457</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-11.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Watches</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Fossil Pair Of 3 in 1 </Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>40 Pcs</span>
                <p>$789</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tab_content" data-tab="laptop">
        <div className="row">
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-02.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Computer</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">MacBook Pro</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>140 Pcs</span>
                <p>$1000</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-07.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Laptop</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">IdeaPad Slim 5 Gen 7</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>74 Pcs</span>
                <p>$1454</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-10.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Computer</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Tablet 1.02 inch</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>14 Pcs</span>
                <p>$4744</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-13.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Laptop</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">Yoga Book 9i</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>65 Pcs</span>
                <p>$4784</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3 pe-2">
            <div className="product-info default-cover card">
              <Link to="#" className="img-bg">
                <ImageWithBasePath
                  src="assets/img/products/pos-product-14.png"
                  alt="Products"
                />
                <span>
                  <Check className="feather-16" />
                </span>
              </Link>
              <h6 className="cat-name">
                <Link to="#">Laptop</Link>
              </h6>
              <h6 className="product-name">
                <Link to="#">IdeaPad Slim 3i</Link>
              </h6>
              <div className="d-flex align-items-center justify-content-between price">
                <span>47 Pcs</span>
                <p>$1245</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ProductPOS;
